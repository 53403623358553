import { useState } from "react";
import { Button, Grid, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { actionRegisterOrganization } from "../../store/winfo";

const RegisterOrganization = () => {
  const dispatch = useDispatch();
  const [formStates, setFormStates] = useState();

  const onChange = (e) => {
    setFormStates((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  const submit = () => {
    const data = formStates;

    dispatch(actionRegisterOrganization(data));
  };
  return (
    <div className="Page Register">
      <div className="MainContainer">
        <div className="div1">
          <div className="div1-left">
            <h1>Rekisteröi organisaatio</h1>
          </div>
        </div>

        <div className="div2">
          <Grid container className="mainGrid">
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Nimi"
                name="name"
                variant="outlined"
                onChange={onChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Kuvaus"
                name="desc"
                variant="outlined"
                onChange={onChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Button onClick={submit}>Luo organisaatio</Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default RegisterOrganization;
