import React, { Fragment } from "react";
import { useEffect, useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { actionSetLastDbTouch } from "../../store/appData";

const DiaCard = React.forwardRef(({ data }, ref) => {
  const dispatch = useDispatch();

  const { lastDbTouch } = useSelector((state) => state.appData);

  const [activeIndex, setActiveIndex] = useState(0);
  const intervalTime = data.interval || 10;
  const [hideLeftArrow, setHideLeftArrow] = useState(false);
  const [hideRightArrow, setHideRightArrow] = useState(false);

  const [pauseAutoSwitch, setPauseAutoSwitch] = useState(false);

  let timer;

  useEffect(() => {
    let interval;
    const max = data.files?.length - 1 || 0;
    // automaattinen dian vaihto
    if (data.switchingType !== "manual" && !pauseAutoSwitch) {
      if (activeIndex === 0) {
        setHideLeftArrow(true);
      } else if (activeIndex === max) {
        setHideRightArrow(true);
      }
      if (max > 0) {
        interval = setTimeout(() => {
          if (activeIndex < max) {
            setActiveIndex(activeIndex + 1);
            setHideLeftArrow(false);
          } else {
            setActiveIndex(0);
            setHideRightArrow(false);
            setHideLeftArrow(true);
          }
        }, intervalTime * 1000);
      } else {
        setActiveIndex(0);
      }
    } else {
      // manualModessa tarkistetaan pitääko nuoli piilottaa
      if (activeIndex === max) {
        setHideRightArrow(true);
      } else {
        setHideRightArrow(false);
      }
      if (activeIndex === 0) {
        setHideLeftArrow(true);
      } else {
        setHideLeftArrow(false);
      }
    }

    return () => clearTimeout(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex, pauseAutoSwitch]);

  useEffect(() => {
    const waitTimeAfterTouch = data.timeOutToAuto || 10;

    if (lastDbTouch >= waitTimeAfterTouch) {
      dispatch(actionSetLastDbTouch(-1));
      setPauseAutoSwitch(false);
      setHideLeftArrow(false);
      setHideRightArrow(false);
    }
  }, [lastDbTouch]);

  const handleManualSlide = (direction) => {
    const max = data.files?.length - 1 || 0;
    dispatch(actionSetLastDbTouch(0));
    setPauseAutoSwitch(true);

    if (direction === "right") {
      if (activeIndex < max) {
        setActiveIndex(activeIndex + 1);
      }
    } else if (direction === "left") {
      if (activeIndex > 0) {
        setActiveIndex(activeIndex - 1);
      }
    }
  };

  if (!data.files) {
    return (
      <div className="DiaCard">
        <p>Virhe</p>
      </div>
    );
  }
  return (
    <div className="DiaCard" ref={ref}>
      {data.switchingType === "manual" ||
      data.switchingType === "autoManual" ? (
        <Fragment>
          {!hideRightArrow && (
            <span
              className="right-direction"
              onClick={() => handleManualSlide("right")}
            >
              <IconButton className="iconBtn">
                <ChevronRightIcon />
              </IconButton>
            </span>
          )}

          {!hideLeftArrow && (
            <span
              className="left-direction"
              onClick={() => handleManualSlide("left")}
            >
              <IconButton className="iconBtn">
                <ChevronLeftIcon />
              </IconButton>
            </span>
          )}
        </Fragment>
      ) : null}
      <div
        style={{
          backgroundImage: `url("${data.files[activeIndex]?.url}")`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      />
    </div>
  );
});

export default DiaCard;

/*
  style={{
        borderStyle: "solid",
        borderWidth: "2xp",
        borderColor: styles?.borderColor || "black",
      }}
*/
