import { useState } from "react";
import axios from "axios";
import { Button, Collapse, Grid, TextField, Typography } from "@mui/material";

import "./WinfoStyles.scss";
import { useEffect } from "react";
import { actionGetOrganizations } from "../../store/winfo";

import { useDispatch, useSelector } from "react-redux";

import BasicSelect from "../../components/Select/BasicSelect";
import proxy from "../../utils/proxy";
import { actionOpenAlert } from "../../store/alert";

const RegisterUser = () => {
  const dispatch = useDispatch();
  const { organizations } = useSelector((state) => state.winfo);
  const [formStates, setFormStates] = useState({});
  const [selectedOrg, setSelectedOrg] = useState(null);

  useEffect(() => {
    dispatch(actionGetOrganizations());
  }, []);
  const onChange = (e) => {
    setFormStates((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  const handleChange = (selectedOption) => {
    setSelectedOrg(selectedOption);
  };

  const submit = () => {
    const data = formStates;

    data.organizationId = selectedOrg?._id;

    axios
      .post(`${proxy}/api/register/user`, data)
      .then((res) => {
        dispatch(
          actionOpenAlert({
            open: true,
            severity: "success",
            text: `Käyttäjä: "${data.name}" lisätty`,
            timeout: 5000,
            autoHide: true,
          })
        );
      })
      .catch((err) => {
        console.log("USER", err.response?.data?.error);
      });
  };
  return (
    <div className="Page Register">
      <div className="MainContainer">
        <div className="div1">
          <div className="div1-left">
            <h1>Rekisteröi käyttäjä</h1>
          </div>
        </div>

        <div className="div2">
          <Grid container spacing={6} className="mainGrid">
            <Grid item xs={12}>
              <Typography variant="h6">Valitse organisaatio</Typography>
              <BasicSelect
                value={selectedOrg}
                handleChange={handleChange}
                options={organizations}
                getLabel="name"
                getValue="_id"
                isMulti={false}
              />
            </Grid>

            <Collapse in={selectedOrg ? true : false}>
              <hr />
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  label="Nimi"
                  name="name"
                  variant="outlined"
                  onChange={onChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  label="Käyttäjänimi"
                  name="username"
                  variant="outlined"
                  onChange={onChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  label="Salasana"
                  name="password"
                  variant="outlined"
                  onChange={onChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <Button onClick={submit} variant="contained">
                  Luo käyttäjä
                </Button>
              </Grid>
            </Collapse>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default RegisterUser;
