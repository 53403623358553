import { useCallback, useEffect, useState } from "react";
import { FormatDate } from "../../utils/dates";
import { AutoTextSize } from "auto-text-size";

const WeatherCard = ({ item, styles }) => {
  const apiKey = process.env.REACT_APP_WEATHER_API_KEY;

  // 20min
  // 1000 * 60 = min
  const int = 1000 * 60 * 20;
  const [weather, setWeather] = useState(null);

  const getWeather = useCallback(async () => {
    const date = FormatDate(new Date(), "DD/MM - HH:mm:ss");
    const state = item.city || "hämeenlinna";
    const url = `https://api.openweathermap.org/data/2.5/weather?units=metric&q=${state}&appid=${apiKey}`;

    if (apiKey) {
      console.log("** GET weather", date);

      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          setWeather(data);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weather]);

  useEffect(() => {
    let interval;
    getWeather();

    interval = setInterval(() => {
      getWeather();
    }, int);

    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!weather) {
    return (
      <div className="WeatherCard">
        <p>LATAA...</p>
      </div>
    );
  }

  if (weather && !weather.main) {
    return (
      <div className="WeatherCard">
        <p>Ei saatavilla</p>
        <p>{weather?.message}</p>
      </div>
    );
  }
  return (
    <div
      className="WeatherCard"
      style={{
        borderStyle: "solid",
        borderWidth: "2xp",
        borderColor: styles?.borderColor || "black",
      }}
    >
      <div className="mainContent">
        <div className="header">
          <AutoTextSize mode="oneline" maxFontSizePx={40}>
            {item?.city?.toUpperCase() || "--"}
          </AutoTextSize>
        </div>

        <div className="content">
          <AutoTextSize mode="oneline">
            {weather.main.temp.toFixed(1)} °C
          </AutoTextSize>

          <img
            className="weather-icon"
            alt="icon"
            src={`http://openweathermap.org/img/wn/${weather.weather[0].icon}@2x.png`}
          />
        </div>

        {item.options?.showFeelsLike || item.options?.showWind ? (
          <div className="footer">
            {item.options?.showFeelsLike ? (
              <AutoTextSize mode="oneline" maxFontSizePx={40}>
                Tuntuu kuin: {weather.main.feels_like.toFixed(1)} °C
              </AutoTextSize>
            ) : null}

            {item.options?.showWind ? (
              <AutoTextSize mode="oneline" maxFontSizePx={40}>
                Tuuli: {weather.wind.speed} m/s
              </AutoTextSize>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default WeatherCard;
/*  {item?.options?.showFeelsLike && (
            <p className="p-feels">
            </p>
          )}
          {item?.options?.showWind && (
            <div className="windDiv">
              <p>Tuuli: {weather.wind.speed} m/s</p>
            </div>
          )}
          

           <div className="tempDiv">
            <p className="p-main">{weather.main.temp.toFixed(1)} °C</p>
            <img
              className="weather-icon"
              alt="icon"
              src={`http://openweathermap.org/img/wn/${weather.weather[0].icon}@2x.png`}
            />
          </div>
          */
