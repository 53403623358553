import { Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import "./Modals.scss";

const ConfirmModal = ({ open, handleClose, title, content, answerModal }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    backgroundColor: "white",
    borderRadius: "1rem",
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="ConfirmModal"
    >
      <Box sx={style} className="modalBox">
        <div className="textDiv">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {content}
          </Typography>
        </div>

        <div className="buttonDiv">
          <Button
            color="primary"
            variant="contained"
            onClick={() => answerModal("yes")}
            fullWidth
          >
            Kyllä
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={() => answerModal("no")}
          >
            Ei
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default ConfirmModal;
