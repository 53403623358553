import Select from "react-select";

const BasicSelect = ({
  value,
  handleChange,
  options,
  getLabel,
  getValue,
  isMulti,
  placeholder,
}) => {
  // value handleChange, options perus
  //getLabel ja getValue - Mäkä kenttä on options arrayssa
  return (
    <Select
      value={value}
      onChange={handleChange}
      options={options}
      getOptionLabel={(option) => `${option[getLabel]}`}
      getOptionValue={(option) => option[getValue]}
      // z-index proble fix?
      menuPortalTarget={document.body}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      isMulti={isMulti}
      placeholder={placeholder || ""}
    />
  );
};

export default BasicSelect;
