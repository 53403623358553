import converLayoutType from "../../utils/converLayoutType";
import "./Layout.scss";

const SelectLayoutType = ({
  showType,
  orientation,
  selectLayout,
  selectedLayout,
  type,
}) => {
  return (
    <div className="SelectLayoutType">
      <h2>{converLayoutType(type)}</h2>
      <div className={`buttonsDiv ${showType}`}>
        <div
          className={`buttonBox-${orientation} full ${
            type === "full" ? "activeLayout" : ""
          }`}
          onClick={() => selectLayout(1, "full")}
        >
          <div className="innerBox" />
        </div>

        <div
          className={`buttonBox-${orientation} sideBySide ${
            type === "side-by-side" ? "activeLayout" : ""
          }`}
          onClick={() => selectLayout(2, "side-by-side")}
        >
          <div className="innerBox" />
          <div className="innerBox" />
        </div>

        <div
          className={`buttonBox-${orientation} upDown ${
            type === "up-down" ? "activeLayout" : ""
          }`}
          onClick={() => selectLayout(2, "up-down")}
        >
          <div className="innerBox" />
          <div className="innerBox" />
        </div>

        <div
          className={`buttonBox-${orientation} grid2x2 ${
            type === "2x2" ? "activeLayout" : ""
          }`}
          onClick={() => selectLayout(4, "2x2")}
        >
          <div className="innerBox" />
          <div className="innerBox" />

          <div className="innerBox" />
          <div className="innerBox" />
        </div>
      </div>
    </div>
  );
};

export default SelectLayoutType;
