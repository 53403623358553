import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import proxy from "../utils/proxy";
import setAuthToken from "../utils/setAuthToken";
import { actionOpenAlert } from "./alert";
import { handleResponses } from "../utils/handleResponses";
import { FormatDate } from "../utils/dates";
import { actionSetRedirectUrl } from "./appData";

// Slice
const slice = createSlice({
  name: "DB",
  initialState: {
    DB: {},
    loadingDB: true,
    DBLoggedIn: false,
    DBLoginData: null,
    DBLoaded: false,
    logoutReady: false,
    DBcardSize: "",
  },
  reducers: {
    getDisplayData: (state, action) => {
      state.DB = action.payload;
      state.loadingDB = false;
    },

    updateDB: (state, action) => {
      state.DB = {
        ...state.DB,
        ...action.payload,
      };
    },

    loginSuccess: (state, action) => {
      state.DBLoginData = {
        token: action.payload.token || null,
        data: action.payload.data || null,
      };

      state.DBLoggedIn = action.payload.DBLoggedIn || false;
      state.DBLoaded = true;
    },

    loginFailed: (state, action) => {
      state.DBLoginData = {
        token: null,
        data: null,
      };

      state.DBLoggedIn = false;
      state.DBLoaded = true;
    },

    logoutSuccess: (state, action) => {
      state.DBLoggedIn = false;
      state.DB = {};
      state.loadingDB = true;
      state.DBLoginData = null;
      state.DBLoaded = false;
    },
    setLogoutReady: (state, action) => {
      state.logoutReady = action.payload;
    },

    setCardSize: (state, action) => {
      state.DBcardSize = action.payload;
    },
  },
});
export default slice.reducer;

// Actions
const {
  getDisplayData,
  updateDB,
  loginSuccess,
  logoutSuccess,
  setLogoutReady,
  loginFailed,
  setCardSize,
} = slice.actions;

export const actionDBLogin = ({
  username,
  password,
  response,
  socket,
}) => async (dispatch) => {
  try {
    const config = {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      username,
      password,
    });

    axios
      .post(`${proxy}/api/DB/login`, body, config)
      .then(async (response) => {
        const data = response.data;

        if (data.refreshToken) {
          if (localStorage.getItem("refreshToken")) {
            localStorage.removeItem("refreshToken");
          }

          localStorage.setItem("refreshToken", data.refreshToken);
        }

        dispatch(loginSuccess(data));
        setAuthToken(data.token);
        localStorage.setItem("isDB", true);

        if (socket) {
          const res = {
            message: "Kirjautuminen onnistui",
            ...response,
            variant: "success",
          };

         socket?.emit("DB_response", res);
        }

        dispatch(
          actionOpenAlert({
            open: true,
            severity: "success",
            text: "Kirjautuminen onnistui",
            timeout: 5000,
            autoHide: true,
          })
        );
      })
      .catch((error) => {
        dispatch(
          actionOpenAlert({
            open: true,
            severity: "error",
            text: "Virheelliset tiedot!",
            timeout: 5000,
            autoHide: true,
          })
        );

        if (socket) {
          const res = {
            message: "Kirjautuminen epäonnistui",
            ...response,
            variant: "error",
          };

         socket?.emit("DB_response", res);
        }

        console.log("LOGIn err", error.message);
      });
  } catch (err) {
    dispatch(handleResponses(err));

    return console.error(err.message);
  }
};

export const actionGetDisplayData = () => async (dispatch) => {
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    console.log("* FETCH DB *", FormatDate(new Date(), "ddd HH:mm:ss"));
    const response = await axios.get(`${proxy}/api/DB/get-data`, config);
    const data = response.data;

    dispatch(getDisplayData(data));

    // setTimeout(() => dispatch(actionGetDisplayData()), 59 * 60 * 1000);
  } catch (err) {
    console.error("VIRHE Ladattaessa näyttöä:", err?.response?.data?.errMsg);
  }
};

export const actionUpdateDBInfo = (data) => (dispatch) => {
  dispatch(updateDB(data));
};

export const actionSetDisplayData = (data) => async (dispatch) => {
  dispatch(getDisplayData(data));
};

export const actionRefreshLoginDB = () => async (dispatch) => {
  // console.log("* REFRESH *");
  let timeout;
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "X-test": "1234",
    },
  };

  try {
    const refresToken = localStorage.getItem("refreshToken");

    if (!refresToken) {
      return dispatch(loginFailed());
    }

    axios
      .get(`${proxy}/api/DB/refreshToken?refreshToken=${refresToken}`, config)
      .then(async (response) => {
        if (response.status === 200) {
          console.log("* REFRES DB *", FormatDate(new Date(), "ddd HH:mm:ss"));

          const data = await response.data;

          if (data.refreshToken) {
            if (refresToken) {
              localStorage.removeItem("refreshToken");
            }

            localStorage.setItem("refreshToken", data.refreshToken);
          }

          dispatch(loginSuccess(data));

          setAuthToken(data.token);

          /*dispatch(

            actionOpenAlert({
              open: true,
              severity: "info",
              text: `REFRES! ${FormatDate(new Date(), "ddd HH:mm:ss")}`,
              timeout: 3000,
              autoHide: false,
            })
          );*/
        } else {
          console.log("fdfdfdffd");
          dispatch(actionSetRedirectUrl("/DB/login"));
          // dispatch(loadUserAction(null));
        }
        clearTimeout(timeout);

        //refresh token every 2h
        timeout = setTimeout(
          () => dispatch(actionRefreshLoginDB()),
          60 * 60 * 1000
        );
      });
  } catch (err) {
    dispatch(
      actionOpenAlert({
        open: true,
        severity: "danger",
        text: `REFRES fail! ${FormatDate(new Date(), "ddd HH:mm:ss")}`,
        timeout: 3000,
        autoHide: false,
      })
    );
  }
};

export const actionDBLogout = (token) => async (dispatch) => {
  try {
    const refresToken = localStorage.getItem("refreshToken");

    fetch(`${proxy}/api/DB/logout?refreshToken=${refresToken}`, {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then(async () => {
      localStorage.clear();
      localStorage.setItem("isDB", true);
      dispatch(logoutSuccess());

      dispatch(
        actionOpenAlert({
          open: true,
          severity: "warning",
          text: "Kirjauduttu ulos!",
          timeout: 5000,
          autoHide: true,
        })
      );
      dispatch(actionSetLogoutReady(true));
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const actionSetLogoutReady = (ready) => (dispatch) => {
  dispatch(setLogoutReady(ready));
};

export const actionSetDBcardSize = (cardSize) => (dispatch) => {
  dispatch(setCardSize(cardSize));
};

export const actionDBLinkDeviceId = ({
  displayId,
  organizationId,
  deviceId,
}) => async (dispatch) => {
  try {
    const config = {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      displayId,
      organizationId,
      deviceId,
    });

    axios
      .post(`${proxy}/api/DB/link-device-id`, body, config)
      .then(async (response) => {
        const data = response.data;

        dispatch(
          actionOpenAlert({
            open: true,
            severity: "success",
            text: "Linkitys onnistui",
            timeout: 5000,
            autoHide: true,
          })
        );
      })
      .catch((error) => {
        dispatch(
          actionOpenAlert({
            open: true,
            severity: "error",
            text: "Linkitys epäonnistui!",
            timeout: 5000,
            autoHide: true,
          })
        );

        console.log("Link err", error.message);
      });
  } catch (err) {
    dispatch(handleResponses(err));

    return console.error(err.message);
  }
};

export const actionGetDBWithLink = ({ deviceId }) => async (dispatch) => {
  try {
    const config = {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const date = new Date().toISOString()
    const response = await axios.get(
      `${proxy}/api/DB/get-db-with-link?deviceId=${deviceId}&clientTime=${date}`,
      config
    );

    const data = response.data;

    // setTimeout(()=>dispatch(getDisplayData(data)), 3000)
    dispatch(getDisplayData(data));

  } catch (err) {

    if (!err.response) {
      console.log('*** OFLINE ***')
    }
    dispatch(handleResponses(err));
    dispatch(getDisplayData(null));

    return console.error(err.message);
  }
};
