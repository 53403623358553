import { FormatDate } from "../../utils/dates";
import "../styles/Notifications.scss";
const Notification = ({ item }) => {
  return (
    <div className="Notification">
      <div className="circle">
        <img alt="logo" src={item.url} />
      </div>

      <div className="content">
        <p className="p-date">
          {FormatDate(item.createdAt, "DD.MM.YY  HH:mm")}
        </p>
        <p className="p-title">{item.title}</p>
        <p className="p-desc">{item.desc}</p>
      </div>
    </div>
  );
};

export default Notification;
