import { Button, FormGroup } from "@mui/material";
import useQueryParam from "../../utils/UseQueryParams";
import "./WinfoStyles.scss";
import BasicSelect from "../../components/Select/BasicSelect";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionGetDisplays } from "../../store/display";
import { actionOpenAlert } from "../../store/alert";
import { actionDBLinkDeviceId } from "../../store/DB";

const LinkDBtoDeviceId = () => {
  const dispatch = useDispatch();
  const [deviceId] = useQueryParam("deviceId");
  const { ADMIN_MODE, organizations, selectedOrganization } = useSelector(
    (state) => state.winfo
  );
  const { displays, loadingDisplays } = useSelector((state) => state.display);

  const [selectedDisplay, setSelectedDisplay] = useState(null);

  useEffect(() => {
    if (!displays || !displays?.length) {
      console.log("EFF", selectedOrganization);
      const params = {
        orgID: selectedOrganization?._id,
      };
      dispatch(actionGetDisplays(params));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganization]);

  const linkDisplay = (e) => {
    e.preventDefault();

    if (!selectedDisplay) {
      return dispatch(
        actionOpenAlert({
          open: true,
          severity: "warning",
          text: `Valitse näyttö`,
          timeout: 2000,
          autoHide: true,
        })
      );
    }

    if (!deviceId) {
      return dispatch(
        actionOpenAlert({
          open: true,
          severity: "warning",
          text: `Laitteen id ei saatavilla`,
          timeout: 2000,
          autoHide: true,
        })
      );
    }

    if (!selectedOrganization) {
      return dispatch(
        actionOpenAlert({
          open: true,
          severity: "warning",
          text: `Valitse organisaatio`,
          timeout: 2000,
          autoHide: true,
        })
      );
    }
    const data = {
      displayId: selectedDisplay._id,
      deviceId,
      organizationId: selectedOrganization._id,
    };

    console.log("SUBMIT", data);

    dispatch(actionDBLinkDeviceId(data));
  };

  console.log("O", selectedOrganization, displays, selectedDisplay);
  return (
    <div className="LinkDBtoDeviceId">
      <div className="headerDiv">
        <h1>LINKITÄ</h1>
        <p>Device id: {deviceId}</p>
        <p>
          Organisaatio: <strong>{selectedOrganization?.name}</strong>
        </p>
        {!selectedOrganization ? (
          <small>
            Organisaatio valitaan yläpalkista (varmista että ADMIN_MODE on
            päällä)
          </small>
        ) : null}
      </div>

      <div className="contentDiv">
        <form onSubmit={linkDisplay}>
          <FormGroup>
            <BasicSelect
              value={selectedDisplay}
              handleChange={(selected) => setSelectedDisplay(selected)}
              options={displays}
              getLabel="name"
              getValue="_id"
              isMulti={false}
              placeholder="Valitse näyttö..."
            />
          </FormGroup>

          <Button variant="contained" type="submit">
            Linkitä
          </Button>
        </form>
      </div>
    </div>
  );
};

export default LinkDBtoDeviceId;
