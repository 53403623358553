import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// Slice
const slice = createSlice({
  name: "appData",
  initialState: {
    showAppbar: true,
    redirectUrl: undefined,
    networkStatus: "-",
    dateAndTime: new Date().toISOString(),
    initialsReady: false,
    lastDbTouch: -1,
  },
  reducers: {
    toggleAppbar: (state, action) => {
      state.showAppbar = action.payload;
    },
    setRedirectUrl: (state, action) => {
      state.redirectUrl = action.payload;
    },
    setNetworkStatus: (state, action) => {
      state.networkStatus = action.payload;
    },
    setDateAndTime: (state, action) => {
      state.dateAndTime = action.payload;
    },
    setInitialsReady: (state, action) => {
      state.initialsReady = action.payload;
    },
    setLastDbTouch: (state, action) => {
      state.lastDbTouch = action.payload.time;
    },
  },
});
export default slice.reducer;
// Actions
const {
  toggleAppbar,
  setRedirectUrl,
  setNetworkStatus,
  setDateAndTime,
  setInitialsReady,
  setLastDbTouch,
} = slice.actions;

export const actionToggleAppbar = (show) => (dispatch) => {
  dispatch(toggleAppbar(show));
};

export const actionSetRedirectUrl = (url) => (dispatch) => {
  dispatch(setRedirectUrl(url));
};

export const actionSetNetworkStatus = (status) => (dispatch) => {
  dispatch(setNetworkStatus(status));
};

export const actionSetDateAndTime = (date) => (dispatch) => {
  dispatch(setDateAndTime(date));
};

export const actionSetInitialsReady = (ready) => (dispatch) => {
  dispatch(setInitialsReady(ready));
};

export const actionSetLastDbTouch = (time) => (dispatch) => {
  dispatch(setLastDbTouch({ time }));
};
