import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import { actionOpenAlert } from "./alert";

import proxy from "../utils/proxy";
import { handleResponses } from "../utils/handleResponses";
import { actionSetInitialsReady } from "./appData";

// Slice
const slice = createSlice({
  name: "user",
  initialState: {
    data: null,
    token: null,
    isLoggedIn: false,
    userLoaded: false,
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.data = action.payload.data;
      state.token = action.payload.token;
      state.isLoggedIn = true;
      state.userLoaded = action.payload.userLoaded;
    },
    logoutSuccess: (state, action) => {
      state.data = null;
      state.token = null;
    },
    loadUserAction: (state, action) => {
      state.token = action.payload?.token || null;
      state.data = action.payload?.data || null;
      state.isLoggedIn = action.payload?.isLoggedIn || null;
      state.userLoaded = action.payload?.userLoaded || true;
    },
    loginFailed: (state, action) => {
      state.userLoaded = true;
    },
    updateUserData: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
  },
});
export default slice.reducer;
// Actions
const {
  loginSuccess,
  logoutSuccess,
  loadUserAction,
  loginFailed,
  updateUserData,
} = slice.actions;

export const actionLogin = ({ username, password }) => async (dispatch) => {
  try {
    window.localStorage.clear();

    const config = {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      username,
      password,
    });

    axios
      .post(`${proxy}/api/user/login`, body, config)
      .then(async (response) => {
        const data = response.data;

        dispatch(loginSuccess(data));
        setAuthToken(data.token);

        dispatch(
          actionOpenAlert({
            open: true,
            severity: "success",
            text: "Kirjautuminen onnistui",
            timeout: 5000,
            autoHide: true,
          })
        );
      })
      .catch((error) => {
        dispatch(
          actionOpenAlert({
            open: true,
            severity: "error",
            text: "Virheelliset tiedot!",
            timeout: 5000,
            autoHide: true,
          })
        );

        console.log("LOGIn err", error.message);
      });
  } catch (e) {
    dispatch(loginFailed());
    return console.error(e.message);
  }
};

export const actionLogout = (token) => async (dispatch) => {
  try {
    fetch(`${proxy}/api/user/logout`, {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then(async () => {
      dispatch(logoutSuccess());
      //poistetaan
      setAuthToken(null);

      dispatch(
        actionOpenAlert({
          open: true,
          severity: "warning",
          text: "Kirjauduttu ulos!",
          timeout: 5000,
          autoHide: true,
        })
      );
    });
    return dispatch(logoutSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};

export const loadUser = () => async (dispatch) => {
  console.log("* LOAD *");
  try {
    const config = {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch(`${proxy}/api/user/refreshToken`, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    }).then(async (response) => {
      if (response.ok) {
        const data = await response.json();

        dispatch(loadUserAction(data));
        setAuthToken(data.token);
      } else {
        dispatch(actionSetInitialsReady(true));

        dispatch(loginFailed());

        dispatch(loadUserAction(null));
      }
      // call refreshToken every 5 minutes to renew the authentication token.
     // setTimeout(() => dispatch(loadUser()), 5 * 60 * 1000);
    });
  } catch (e) {
    dispatch(loginFailed());
    dispatch(actionSetInitialsReady(true));

    return console.error(e.message);
  }
};

export const actionUpdateMyAccount = ({
  name,
  oldPW,
  pw1,
  pw2,
  updatePW,
}) => async (dispatch) => {
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const body = JSON.stringify({
      name,
      oldPW,
      pw1,
      pw2,
      updatePW,
    });

    const res = await axios.post(
      `${proxy}/api/user/update-my-account`,
      body,
      config
    );

    const data = res.data;

    console.log("DAAA", data);

    dispatch(updateUserData(data));

    dispatch(
      actionOpenAlert({
        open: true,
        severity: "success",
        text: `päivitetty`,
        timeout: 2000,
        autoHide: true,
      })
    );
  } catch (err) {
    const data = err;
    // antaa suoraan alert ilmoituksen
    dispatch(handleResponses(data));
  }
};
