import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, Step, StepButton, Stepper } from "@mui/material";

const BasicStepperModal = ({
  open,
  title,
  children,
  activeStep,
  nextStep,
  prevStep,
  steps,
  finalAction,
  formStates,
  onClose,
}) => {
  if (!open) {
    return null;
  }

  const isFinalStep = activeStep === steps.length - 1;

  return (
    <div className="BasicStepperModal">
      <div className="rootDiv">
        <div className="innerRoot">
          <div className="header">
            <h2>{title}</h2>
            <IconButton size="large" aria-haspopup="true" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>

          <div className="contentRoot">
            <div className="stepperDiv">
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepButton color="inherit">{label}</StepButton>
                  </Step>
                ))}
              </Stepper>
            </div>
            <div className="content">{children}</div>
          </div>

          <div className="footer">
            <Button onClick={prevStep}>Edellinen</Button>

            <Button onClick={isFinalStep ? finalAction : nextStep}>
              {isFinalStep ? "Valmis" : "Seuraava"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicStepperModal;
