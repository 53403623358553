import { useState } from "react";
import {
  Button,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import "./WinfoStyles.scss";
import { useEffect } from "react";
import {
  actionGetAllUsers,
  actionGetOrganizations,
  actionResetUserPW,
  actionSetActionStatus,
} from "../../store/winfo";

import { useDispatch, useSelector } from "react-redux";

import BasicSelect from "../../components/Select/BasicSelect";
import LockResetIcon from "@mui/icons-material/LockReset";
import { actionCloseModal, actionOpenModal } from "../../store/modal";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RefreshIcon from "@mui/icons-material/Refresh";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useNavigate } from "react-router-dom";

const FindUser = () => {
  const dispatch = useDispatch();

  // actionStatus kertoo onnistuiko kutsu ajatuksena kolme tilaa:
  // "", "success", "failed" -> "failed" tilaa tarvitaan harvoin, koska try catch hoitaa virheet
  // resData sisältää tarvittavaa tietoa backendiltä esim palautetun salasanan
  const { organizations, foundUsers, actionStatus, resData } = useSelector(
    (state) => state.winfo
  );
  const [formStates, setFormStates] = useState({});

  // Valittu organisaatio listasta
  const [selectedOrg, setSelectedOrg] = useState(null);

  // modaalin vastaus ja onko se avoinna ("yes" tai "no")
  // voidaan siis käyttää useassa eri tarkoituksessa samaa modaalia
  const { answer, open } = useSelector((state) => state.modal);

  // määrittää confirmModalille mitä ollaan tekemässä
  // nyt esim reset_pw käytössä
  // reset modal palauttaa vain "yes" tai "no" jota useEffect "kuuntelee" -> pitää tietää mitä ns. vahvistetaan
  const [action, setAction] = useState(undefined);

  // valittu käyttäjä esim. salasanan resetointiin
  const [selectedUserName, setSelectedUserName] = useState(undefined);

  // Haetaan organisaatiot kun tullaan sivulle
  useEffect(() => {
    dispatch(actionGetOrganizations());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // hoitaa modaalin vastaukset
  useEffect(() => {
    if (answer === "yes") {
      console.log("Confirmed", action);

      // jos kyseessä salasanan palautus
      if (action === "reset_pw") {
        dispatch(actionResetUserPW(selectedUserName));
      }
    } else if (open) {
      // käyttäjä valitsee "no" -> nollataan valittuja
      console.log("cancelled", action);
      dispatch(actionCloseModal());
      setAction(undefined);
      setSelectedUserName(undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer]);

  useEffect(() => {
    // jos esim. salasanan palautus onnistui, niin nollataan tilat joita ei enää tarvita
    if (actionStatus === "success") {
      dispatch(actionCloseModal());
      dispatch(actionSetActionStatus(""));
      setAction(undefined);
      setSelectedUserName(undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionStatus]);

  // select
  const handleChange = (selectedOption) => {
    setSelectedOrg(selectedOption);
  };

  // etsi käyttäjiä klikattu
  const searchUser = () => {
    const data = formStates;

    data.organizationId = selectedOrg?._id;

    dispatch(actionGetAllUsers(data));
  };

  // avataan modaali
  // modaltype määritetään Modals.jsx tiedostossa
  const confirmAction = (username, action) => {
    const data = {
      modalType: 1,
      open: true,
      title: `Käyttäjä: ${username} `,
      content: "Salasana palautetaan?",
    };
    dispatch(actionOpenModal(data));
    setAction(action);
    setSelectedUserName(username);
  };

  return (
    <div className="Page FindUser">
      <div className="MainContainer">
        <div className="div1">
          <div className="div1-left">
            <h1>Etsi käyttäjä</h1>
          </div>
        </div>

        <div className="div2">
          <Grid container spacing={6} className="mainGrid">
            <Grid item xs={12}>
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => window.location.reload()}
                className="refresIcon"
              >
                <RefreshIcon />
              </IconButton>

              <Typography variant="h6">Valitse organisaatio</Typography>
              <BasicSelect
                value={selectedOrg}
                handleChange={handleChange}
                options={organizations}
                getLabel="name"
                getValue="_id"
                isMulti={false}
              />
            </Grid>

            <Collapse in={selectedOrg ? true : false}>
              <div className="collapseDiv">
                <Button onClick={searchUser} variant="contained">
                  Etsi käyttäjiä
                </Button>
              </div>
            </Collapse>
          </Grid>

          <Grid container spacing={2} className="mainGrid">
            {foundUsers?.length ? (
              foundUsers.map((el, i) => (
                <Grid item xs={12} md={6} key={i}>
                  <UserCard
                    user={el}
                    confirmAction={confirmAction}
                    newPW={
                      resData && resData._id === el._id
                        ? resData.newPW
                        : undefined
                    }
                  />
                </Grid>
              ))
            ) : (
              <p>EI tuloksia</p>
            )}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default FindUser;

const UserCard = ({ user, confirmAction, newPW }) => {
  const [showPW, setShowPW] = useState(false);
  const navigate = useNavigate();

  const toggleShowPW = () => {
    setShowPW((state) => !state);
  };

  return (
    <div className="UserCard">
      <div className="innerBox">
        <div className="header">
          <p>
            <strong>{user.name}</strong>
          </p>
          <small>ID: {user._id}</small>
        </div>

        <div className="content">
          <ul>
            <li>
              Käyttäjänimi: <strong>{user.username}</strong>
            </li>

            <li>Yritys: {user.organizationId}</li>

            <li>Rooli: {user.role}</li>

            <li>Kirjautuminen: {user.authStrategy}</li>
          </ul>

          {newPW && (
            <>
              <TextField
                id="outlined-basic"
                label="Uusi salasana"
                type={showPW ? "text" : "password"}
                variant="outlined"
                fullWidth
                disabled
                value={newPW}
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleShowPW}
                      >
                        {showPW ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </>
          )}
        </div>

        <div className="footer">
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => confirmAction(user.username, "reset_pw")}
            color="inherit"
          >
            <LockResetIcon />
          </IconButton>

          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => navigate(`${user._id}`)}
            color="inherit"
          >
            <OpenInNewIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};
