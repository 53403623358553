export const getRss = async (rssUrl) => {
  const res = await fetch(`https://api.allorigins.win/get?url=${rssUrl}`);

  const { contents } = await res.json();
  const feed = new window.DOMParser().parseFromString(contents, "text/xml");

  const items = feed.querySelectorAll("item");

  const feedItems = [...items].map((el) => ({
    //  link: el.querySelector("link").innerHTML,
    title: el ? el.querySelector("title").innerHTML : "",
    description: el ? el.querySelector("description").innerHTML : "",
    imgSrc: el ? el.querySelector("enclosure").attributes[0].textContent : "",
  }));

  return feedItems;
};
