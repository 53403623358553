import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  actionGenerateSignedUrl,
  actionUploadFileToBank,
} from "../../store/fileBank";
import { Button } from "@mui/material";

const CloudinaryUploadWidget = () => {
  const dispatch = useDispatch();
  const { cardId } = useParams();

  const { data } = useSelector((state) => state.user);
  const { selectedOrganization } = useSelector((state) => state.winfo);

  useEffect(() => {
    //Cloudinaryn media library selkeämmäksi
    // jokaisen firman tiedostot omissa kansiossa
    const HOST = window.location.hostname || "HOST";

    const isProd = HOST === "iwip.fi";

    const folder = isProd
      ? `/iwip/${HOST}/${selectedOrganization?._id || data.organizationId}`
      : `/dev/${HOST}/${selectedOrganization?._id || data.organizationId}`;

    console.log("GET", folder);

    //  console.log("FOLDER", folder);

    let preset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET_DEV;

    if (isProd) {
      preset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET_PROD;
    }

    var myWidget = window.cloudinary.createUploadWidget(
      {
        cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
        api_key: process.env.REACT_APP_CLOUDINARY_API_KEY,
        uploadPreset: preset,
        folder: folder,
        cropping: "true",
        croppingCoordinatesMode: "custom",
        uploadSignature: generateSignature,
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
          console.log("Done! Here is the image info: ", cardId, result.info);

          const data = {
            url: result.info.secure_url,
            publicId: result.info.public_id,
            thumbnail: result.info.thumbnail_url,
            format: result.info.format,
            orgID: selectedOrganization?._id,
          };

          dispatch(actionUploadFileToBank(data));
        }
      }
    );
    document.getElementById("upload_widget").addEventListener(
      "click",
      function () {
        myWidget.open();
      },
      false
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateSignature = async (callback, params_to_sign) => {
    try {
      // getSignature is calling an API, passing the params_to_sign
      // in the request body
      // and returns the signature
      const signature = await dispatch(actionGenerateSignedUrl(params_to_sign));
      callback(signature);
    } catch (err) {
      console.log(err);
    }
  };

  if (!data.organizationId) {
    return null;
  }
  return (
    <Button
      id="upload_widget"
      variant="contained"
      className="cloudinary-button"
    >
      Lataa kuva
    </Button>
  );
};

export default CloudinaryUploadWidget;
