import React, { useState } from "react";
import CloudinaryUploadWidget from "./CloudinaryWindget";

export default function FileUpload({ cardId }) {
  /* const handleChange = (event) => {
    setSelectedImage(event.target.files[0]);
  };

  const upload = async (formData) => {
    // response stores the response back from the API
    await axios
      .post(`http://localhost:8082/api/cards/${cardId}/file`, formData)
      .catch((error) => {
        alert(
          "Error occurred while uploading picture, try uploading a smaller image size or try again later."
        );
        return;
      });
  };

  const handleImageUpload = () => {
    if (!selectedImage) {
      return;
    }
    const formData = new FormData();
    formData.append("image", selectedImage);

    upload(formData)
      .then((uploadedImage) => {
        console.log(uploadedImage);
      })
      .catch((_) => {
        console.log("Oooops, something went wrong!");
      });
  };*/

  return (
    <>
      <CloudinaryUploadWidget />
    </>
  );
}

/* const handleSubmit = async () => {
    // response stores the response back from the API
    await axios
      .post(`http://localhost:8082/api/cards/${cardId}/file`, picFile)
      .catch((error) => {
        alert(
          "Error occurred while uploading picture, try uploading a smaller image size or try again later."
        );
        return;
      });
  };
*/
