import { createSlice } from "@reduxjs/toolkit";

// Slice
const slice = createSlice({
  name: "modal",
  initialState: {
    modalType: "",
    open: false,
    title: "",
    content: "",
    answer: "",
    data: null,
  },
  reducers: {
    openModal: (state, action) => {
      state.modalType = action.payload.modalType;
      state.open = action.payload.open;
      state.title = action.payload.title;
      state.content = action.payload.content;
      state.answer = action.payload.answer || "";
      state.data = action.payload.data || null;
    },
    answerModal: (state, action) => {
      state.answer = action.payload.answer;
    },
    closeModal: (state) => {
      state.modalType = "";
      state.open = false;
      state.title = "";
      state.content = "";
      state.answer = "";
      state.data = null;
    },
  },
});
export default slice.reducer;
// Actions
const { openModal, answerModal, closeModal } = slice.actions;

export const actionOpenModal = ({ modalType, open, title, content, data }) => (
  dispatch
) => {
  dispatch(openModal({ open, title, content, modalType, data}));
};

export const actionCloseModal = () => (dispatch) => {
  dispatch(closeModal());
};

export const actionAnswerModal = (answer) => (dispatch) => {
  dispatch(answerModal({ answer }));
};
