import {
  Button,
  Collapse,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionUpdateMyAccount } from "../../store/user";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import "./User.scss";

const MyAccount = () => {
  const dispatch = useDispatch();
  const { data: myData } = useSelector((state) => state.user);

  const [userData, setUserData] = useState(null);
  const [showPW, setShowPW] = useState(false);

  useEffect(() => {
    setUserData((state) => ({ ...state, ...myData }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myData]);

  const onChange = (e) => {
    setUserData((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  const handleCheck = (e) => {
    setUserData((state) => ({ ...state, [e.target.name]: e.target.checked }));
  };

  const submit = () => {
    dispatch(actionUpdateMyAccount(userData));
  };

  const toggleShowPW = () => {
    setShowPW((state) => !state);
  };

  return (
    <div className="Page MyAccount">
      <div className="MainContainer">
        <div className="div1">
          <div className="div1-left">
            <h1>Minun käyttäjä:</h1>
          </div>
        </div>

        <div className="div2">
          <Grid container spacing={6} className="mainGrid">
            <Collapse in={userData ? true : false}>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  label="Nimi"
                  name="name"
                  variant="outlined"
                  onChange={onChange}
                  fullWidth
                  value={userData?.name || ""}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  label="Käyttäjänimi"
                  variant="outlined"
                  fullWidth
                  value={userData?.username || ""}
                  disabled
                  helperText="Käyttäjänimen muutoksen hoitaa Winfo Oy"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  label="Käyttäjätaso"
                  name="username"
                  variant="outlined"
                  fullWidth
                  value={userData?.role || ""}
                  disabled
                  helperText="Käyttäjätason muutoksen hoitaa Winfo Oy"
                />
              </Grid>

              {userData && (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        name="updatePW"
                        color="secondary"
                        checked={userData.updatePW || false}
                        onChange={handleCheck}
                      />
                    }
                    label="Vaihda salasana"
                  />
                </FormGroup>
              )}

              <Collapse in={userData?.updatePW || false}>
                <div className="PWDiv">
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-basic"
                      label="Vanha salasana"
                      name="oldPW"
                      variant="outlined"
                      onChange={onChange}
                      fullWidth
                      value={userData?.oldPW || ""}
                      type={showPW ? "text" : "password"}
                      InputProps={{
                        // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={toggleShowPW}
                            >
                              {showPW ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id="outlined-basic"
                      label="Uusi salasana"
                      name="pw1"
                      variant="outlined"
                      onChange={onChange}
                      fullWidth
                      value={userData?.pw1 || ""}
                      type={showPW ? "text" : "password"}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id="outlined-basic"
                      label="Uusi salasana uudelleen"
                      name="pw2"
                      variant="outlined"
                      onChange={onChange}
                      fullWidth
                      value={userData?.pw2 || ""}
                      type={showPW ? "text" : "password"}
                    />
                  </Grid>
                </div>
              </Collapse>

              <Grid item xs={12}>
                <Button onClick={submit} variant="contained" fullWidth>
                  Tallenna
                </Button>
              </Grid>
            </Collapse>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
