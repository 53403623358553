import {
  Button,
  CircularProgress,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThreeCards } from "../../layouts/CardsLayouts";
import {
  actionDeleteDisplay,
  actionGetDisplays,
  actionNewDisplay,
  actionSetOneDisplay,
} from "../../store/display";

import "../styles/Pages.scss";
import { actionCloseModal, actionOpenModal } from "../../store/modal";
import { useNavigate } from "react-router-dom";
import DisplayCard from "../../components/cards/DisplayCard";
import OnlyForWinfo from "../../components/Winfo/OnlyForWinfo";
import NoItems from "../../components/NoItems/NoItems";

import { FormatDate } from "../../utils/dates";
import AddItemCard from "../../components/cards/AddItemCard";
import { displayRoleOpt } from "../../utils/options";

const DisplaysPage = ({ socket }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showNewForm, setShowNewForm] = useState(false);
  const [formStates, setFormStates] = useState();
  const [selectedId, setSelectedId] = useState(null);
  const [openDb, setOpenDb] = useState(false);

  const { displays, loadingDisplays } = useSelector((state) => state.display);
  const { answer, open } = useSelector((state) => state.modal);
  const { selectedOrganization } = useSelector((state) => state.winfo);
  const { data } = useSelector((state) => state.user);

  const { dateAndTime } = useSelector((state) => state.appData);

  useEffect(() => {
    if (!displays || !displays?.length) {
      console.log("EFF", selectedOrganization);
      const params = {
        orgID: selectedOrganization?._id,
      };
      dispatch(actionGetDisplays(params));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganization]);

  useEffect(() => {
    if (answer === "yes") {
      // ei tarkastus organizationId osalta backillä -> ei syötetä orgID
      deleteDisplay(selectedId);
    } else if (open) {
      dispatch(actionCloseModal());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer]);

  const onChange = (e) => {
    setFormStates((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  const submit = () => {
    let data = formStates;
    const content = {
      name: data?.name,
      desc: data?.desc,
      username: data?.username,
      password: data?.password,
      organizationId: selectedOrganization
        ? selectedOrganization._id
        : undefined,
      role: data?.role || "public",
    };

    console.log("new", content);
    dispatch(
      actionNewDisplay(content)
    );
    setFormStates(null);
  };

  const deleteDisplay = (id) => {
    dispatch(actionDeleteDisplay(id));
  };

  const confirmAction = (id, name) => {
    setSelectedId(id);
    console.log("CONF", id, name);

    const data = {
      modalType: 1,
      open: true,
      title: `Haluatko varmasti poistaa: ${name} `,
      content: "Se poistetaan pysyvästi",
    };
    dispatch(actionOpenModal(data));
  };

  const addForm = (
    <div className="newForm">
      <h2>Lisää</h2>

      <TextField
        id="outlined-basic"
        label="Nimi"
        name="name"
        variant="outlined"
        onChange={onChange}
        value={formStates?.name || ""}
      />

      <TextareaAutosize
        aria-label="empty textarea"
        placeholder="Kuvaus..."
        name="desc"
        onChange={onChange}
        value={formStates?.desc || ""}
        minRows="3"
      />

      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Näytön rooli</InputLabel>
        <Select
          //  value={repeatOptions?.weekType || "all"}
          label="Näytön rooli"
          onChange={onChange}
          name="role"
          defaultValue="public"
        >
          {displayRoleOpt.map((el, i) => (
            <MenuItem key={i} value={el.value}>
              {el.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {formStates?.role === "basic" && (
        <Fragment>
          <TextField
            id="outlined-basic"
            label="Käyttäjänimi"
            name="username"
            variant="outlined"
            onChange={onChange}
            value={formStates?.username || ""}
          />

          <TextField
            id="outlined-basic"
            label="Salasana"
            name="password"
            variant="outlined"
            onChange={onChange}
            value={formStates?.password || ""}
          />
        </Fragment>
      )}

      <Button onClick={submit} variant="contained" color="primary">
        Tallenna
      </Button>
    </div>
  );

  const selectExpand = (item) => {
    const id = item?._id;

    setOpenDb(true);

    if (id && id !== selectedId) {
      setSelectedId(id);
      dispatch(actionSetOneDisplay(item));
    } else {
      setSelectedId(null);
      setOpenDb(false);
      dispatch(actionSetOneDisplay(null));
    }
  };

  return (
    <div className="Page DisplaysPage">
      <div className="MainContainer">
        <div className="div1">
          <div className="div1-left">
            <h1>Tervetuloa, "{data.username}"</h1>
          </div>
          <div className="div1-right">
            <p>{FormatDate(dateAndTime, "ddd DD.MM.YYYY")}</p>
            <p>{FormatDate(dateAndTime, "HH:mm:ss")}</p>
          </div>
        </div>

        <div className="div2">
          {loadingDisplays ? (
            <CircularProgress color="inherit" />
          ) : displays && displays?.length ? (
            <ThreeCards>
              {displays.map((el, i) => (
                <Grid item key={i} xs={12} md={4}>
                  <DisplayCard
                    item={el}
                    confirmAction={() => confirmAction(el._id, el.name)}
                    isDisplay={true}
                    // handleClick={() => handleClick(el)}
                    handleClick={() =>
                      navigate(
                        `/db/?id=${el._id}&secret=${el.secret}&preview=true`
                      )
                    }
                    socket={socket}
                    selectExpand={selectExpand}
                    selectedId={selectedId}
                    openDb={openDb}
                  />
                </Grid>
              ))}

              <OnlyForWinfo>
                <Grid item xs={12} md={4}>
                  <AddItemCard
                    title="Uusi näyttö"
                    onClick={() => setShowNewForm(!showNewForm)}
                    showNew={showNewForm}
                  />
                </Grid>
              </OnlyForWinfo>
            </ThreeCards>
          ) : (
            <NoItems
              title="Näyttöjä ei löytynyt!"
              text="Näyttöjen luonti onnistuu tilaamalla uusi näyttö Winfo Oy:ltä"
            >
              <OnlyForWinfo>
                <AddItemCard
                  title="Uusi näyttö"
                  onClick={() => setShowNewForm(!showNewForm)}
                  showNew={showNewForm}
                />
              </OnlyForWinfo>
            </NoItems>
          )}
        </div>

        <Collapse in={showNewForm}>{addForm}</Collapse>
      </div>
    </div>
  );
};

export default DisplaysPage;
