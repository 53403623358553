import { createSlice } from "@reduxjs/toolkit";

// Slice
const slice = createSlice({
  name: "toast",
  initialState: {
    open: false,
    text: "",
    variant: "",
  },
  reducers: {
    openToast: (state, action) => {
      state.open = action.payload.open;
      state.text = action.payload.text;
      state.timeout = action.payload.timeout;
      state.variant = action.payload.variant;
      state.autoHide = action.payload.autoHide;
    },
  },
});
export default slice.reducer;
// Actions
const { openToast } = slice.actions;

export const actionSetToast = ({ open, text, timeout, variant, autoHide }) => (
  dispatch
) => {
  dispatch(openToast({ open, text, timeout, variant, autoHide }));
};

export const actionCloseToast = () => (dispatch) => {
  dispatch(openToast({ open: false, text: "" }));
};
