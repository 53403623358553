import { Button, TextField } from "@mui/material";
import useQueryParam from "../../utils/UseQueryParams";
import { useFormik } from "formik";

const RegisterDB = ({ socket }) => {
  const [DBSOCKETID] = useQueryParam("socketId");

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: (values) => {
      const socketData = {
        username: values.username,
        password: values.password,
        to: DBSOCKETID,
      };

      socket?.emit("DB_LOGIN", socketData);
    },
  });

  return (
    <div className="Page RegisterDb">
      <div className="header">
        <h2>Rekisteröi näyttö</h2>
        <p>Socket: {DBSOCKETID}</p>
      </div>

      <div className="loginForm SmallShadow">
        <TextField
          id="outlined-basic"
          label="Käyttäjänimi"
          type="text"
          name="username"
          variant="outlined"
          fullWidth
          onChange={formik.handleChange}
        />

        <TextField
          id="outlined-basic"
          label="Salasana"
          name="password"
          variant="outlined"
          fullWidth
          onChange={formik.handleChange}
          type="password"
        />

        <Button
          variant="contained"
          color="primary"
          onClick={formik.handleSubmit}
        >
          Kirjaudu
        </Button>
      </div>

      <div className="footer" />
    </div>
  );
};

export default RegisterDB;
