import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DBContent from "./DBContent";
import { actionGetDBWithLink, actionSetDisplayData } from "../../store/DB";
import { v4 as uuidv4 } from "uuid";
import QRCode from "react-qr-code";
import { CircularProgress, FormControl, TextField } from "@mui/material";
import useQueryParam from "../../utils/UseQueryParams";

const DBUrl = ({ usePreview, preDB, closePreview }) => {
  const dispatch = useDispatch();
  const { DB, loadingDB, DBLoginData } = useSelector((state) => state.DB);
  const { networkStatus } = useSelector((state) => state.appData);
  const idName = process.env.REACT_APP_DB_ID_NAME_LOCALSTORAGE;
  const hostname = window.location.host;
  const protocol = window.location.protocol;
  const [nucMac] = useQueryParam("kiosk");

  const [states, setStates] = useState(null);

  const [deviceId, setDeviceId] = useState(localStorage.getItem(idName));
  /* const pollingDelay = DB?.pollingDelay
    ? DB.pollingDelay * 60 * 1000
    : 5 * 1000 * 60;*/
  const pollingDelay = 1000 * 60 * 3;

  useEffect(() => {
    let interval;
    if (usePreview) {
      dispatch(actionSetDisplayData(preDB));
    } else {
      const data = JSON.parse(localStorage.getItem("DB_offline")) || {};
      dispatch(actionSetDisplayData(data));

      if (!deviceId) {
        let deviceId =
          nucMac && nucMac.length > 3 ? nucMac : `${uuidv4()}+${Date.now()}`;
        setDeviceId(deviceId);

        localStorage.setItem(idName, deviceId);
      }

      if (!usePreview) {
        console.log(" * FETCH URL *", deviceId);
        dispatch(actionGetDBWithLink({ deviceId }));

        // 3min
        interval = setInterval(() => {
          console.log(" * FETCH URL 2*");
          dispatch(actionGetDBWithLink({ deviceId }));
        }, pollingDelay);
      }
    }

    return () => clearInterval(interval);
  }, [deviceId]);

  useEffect(() => {
    if (DB && DB.name) {
      // jos saatu ladattua DB objecti, asetetaan se muistiin offlinea varten
      localStorage.setItem("DB_offline", JSON.stringify(DB));
    }
  }, [DB]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setStates((state) => ({ ...state, [name]: value }));
  };
  const enterIdManually = () => {
    // jos localstoragesta jostain syystä poistuu deviceID, voidaan antaa vanha id manuaalisesti
    if (states?.deviceId) {
      localStorage.setItem(idName, states?.deviceId);
      window.location.reload();
    }
  };

  return (
    <div className="RootRoot">
      {!networkStatus && DB && DB.name ? (
        <DBContent
          DB={DB}
          DBLoginData={DBLoginData}
          usePreview={usePreview}
          closePreview={closePreview}
        />
      ) : loadingDB ? (
        <CircularProgress color="primary" />
      ) : DB && DB.name ? (
        <DBContent
          DB={DB}
          DBLoginData={DBLoginData}
          usePreview={usePreview}
          closePreview={closePreview}
        />
      ) : deviceId ? (
        <div className="RootRoot">
          <div className="qrCodeBox">
            <QRCode
              className="qrBox"
              size={256}
              style={{ maxWidth: "100%", width: "100%" }}
              value={`${protocol}//${hostname}/link-display?deviceId=${deviceId}`}
              viewBox={`0 0 256 256`}
              onClick={enterIdManually}
            />

            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="Device id"
                variant="outlined"
                onChange={handleChange}
                name="deviceId"
                defaultValue={deviceId}
              />
            </FormControl>
          </div>
        </div>
      ) : (
        <div className="RootRoot">
          <h1>NO DEVICE ID PROVIDED!</h1>
        </div>
      )}
    </div>
  );
};

export default DBUrl;
