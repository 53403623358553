import { Button } from "@mui/material";
import { useState } from "react";

const DeviceInfoPage = () => {
  const [data, setData] = useState(null);
  const test = () => {
    fetch("http://127.0.0.1:8080/get-mac")
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        console.log("*DATA*", data);
      })
      .catch((error) => console.error(error));
  };

  const reboot = () => {
    fetch("http://127.0.0.1:8080/reboot")
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        console.log("*DATA*", data);
      })
      .catch((error) => console.error(error));
  };

  return (
    <div style={{ backgroundColor: "white", minHeight: "100vh" }}>
      <h1>Info</h1>

      <Button onClick={() => test()}>Load</Button>

      <Button onClick={() => reboot()}>Reboot</Button>

      <ul>
        <li>{data?.data}</li>
      </ul>
    </div>
  );
};

export default DeviceInfoPage;
