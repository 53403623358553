import moment from "moment";
import "moment/locale/fi"; // without this line it didn't work

export const FormatDate = (date, format) => {
  return moment(date).format(format);
};

export function AddToDate(date, number, unit, format) {
  if (format) {
    return moment(date).add(number, unit).format(format);
  }

  return moment(date).add(number, unit);
}

export function GetDurationToTime(startDate, targetDate) {
  let duration;
  const now = moment(new Date()); //todays date

  if (startDate) {
    const start = moment(startDate);

    duration = moment.duration(start.diff(targetDate));
  } else {
    duration = moment.duration(now.diff(targetDate));
  }
  const units = duration.asSeconds();

  return units * -1;
}
