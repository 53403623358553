import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import proxy from "../utils/proxy";
import { v4 as uuidv4 } from "uuid";

// Slice
const slice = createSlice({
  name: "notification",
  initialState: { notifications: [], dbNotifications: [] },
  reducers: {
    setNotification: (state, action) => {
      state.dbNotifications = [action.payload, ...state.dbNotifications];
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    removeNotification: (state, action) => {
      console.log("red", action.payload);
      state.dbNotifications = state.dbNotifications.filter(
        (el) => el._id !== action.payload
      );
    },
  },
});
export default slice.reducer;
// Actions
const { setNotification, setNotifications, removeNotification } = slice.actions;

export const actionNewNotification = ({
  title,
  desc,
  displays,
  removeAfter,
  url
}) => async (dispatch) => {
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const body = JSON.stringify({
      title,
      desc,
      displays,
      removeAfter,
      url
    });

    const response = await axios.post(
      `${proxy}/api/notifications/new`,
      body,
      config
    );
    const data = response.data;

    data.updateType = "notification_to_db";

    console.log("DATA", data);
    dispatch(setNotifications(data));
  } catch (err) {
    console.log("VIRHE luodessa ilmoitusta:", err.message);
  }
};

export const actionGetNotifications = () => async (dispatch) => {
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.post(`${proxy}/api/notifications/`, config);
    const data = response.data;

    dispatch(setNotifications(data));
  } catch (err) {
    console.log("VIRHE Ladattaessa ilmoituksia:", err.message);
  }
};

export const actionSetNotification = ({
  socket,
  title,
  desc,
  displays,
  createdAt,
  url,
  removeAt,
}) => (dispatch) => {
  const id = uuidv4();

  let data = {
    _id: id,
    title,
    desc,
    displays,
    createdAt,
    updateType: "notification_to_db",
    url,
    removeAt,
  };

  dispatch(setNotification(data));

/*  if (socket) {
   socket?.emit("DB_update", data);
  }*/
};

export const actionRemoveNotification = (id) => (dispatch) => {
  dispatch(removeNotification(id));
};
