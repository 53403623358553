import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  TextField,
} from "@mui/material";

import "../styles/Cards.scss";
import converLayoutType from "../../utils/converLayoutType";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DB from "../../pages/DB/DB";
import AlarmIcon from "@mui/icons-material/Alarm";
import {
  scheduleWeekOpt,
  timerDurationOpt,
  weekdaysOpt,
} from "../../utils/options";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { actionSetScheduleToDisplay } from "../../store/display";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import BasicDropdown from "../Menu/BasicDropdown";

const LayoutCard = ({
  item,
  onClick,
  deleteLayout,
  selectedDPId,
  displays,
  submitUpdateLayout,
  selectedDPName,
  socket,
  bottomBar,
  topBar,
  fromOneDisplay,
}) => {
  const dispatch = useDispatch();

  const { selectedOrganization } = useSelector((state) => state.winfo);

  const isActive = item.displays?.some((db) => db._id === selectedDPId);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [selectedDisplays, setSelectedDisplays] = useState([]);
  const [clickedLayout, setClickedLayout] = useState(null);
  const [showCheck, setShowCheck] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [settingTimer, setSettingTimer] = useState(false);

  const [timerValues, setTimerValues] = useState({
    startDate: null,
    endDate: null,
    name: "",
  });

  const [repeatOptions, setRepeatOptions] = useState({
    active: true,
    days: [],
    weekType: "all",
  });

  const menuRef = useRef();

  useEffect(() => {
    let temp = item.displays;
    setSelectedDisplays(temp);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl]);

  const handleClick = (event, id, showCheck) => {
    setAnchorEl(event.currentTarget);
    setClickedLayout(id);
    setShowCheck(showCheck);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setClickedLayout(null);
  };

  const handleCheckDisplay = (dp) => {
    const alreadySelected = selectedDisplays.some((el) => el._id === dp._id);

    let temp = selectedDisplays;

    if (alreadySelected) {
      temp = temp.filter((el) => el._id !== dp._id);
    } else {
      temp = [...temp, { _id: dp._id, name: dp.name }];
    }

    setSelectedDisplays(temp);
  };

  const submitDisplays = (showCheck) => {
    // selected cards tarvitaan backend kutsuun
    let otherDisplays = item.displays || [];
    const data = !showCheck
      ? {
          layoutId: clickedLayout,
          selectedDisplays: [
            ...otherDisplays,
            { _id: selectedDPId, name: selectedDPName },
          ],
          selectedCards: item.cards2,
          ii: 1,
        }
      : {
          layoutId: clickedLayout,
          selectedDisplays: selectedDisplays,
          selectedCards: item.cards2,
          ii: 2,
        };

    submitUpdateLayout(data);
    setAnchorEl(null);
  };

  const togglePreview = () => {
    setOpenPreview(!openPreview);
  };

  const timerClicked = () => {
    setSettingTimer((state) => !state);
  };

  const handleRepeatOptions = (e) => {
    const { name, value, checked } = e.target;
    let value2 = value;

    if (name === "active") {
      value2 = checked;
    }

    setRepeatOptions((state) => ({
      ...state,
      [name]: value2,
    }));
  };

  const handleDate = (name, date) => {
    setTimerValues((state) => ({
      ...state,
      [name]: date, //.toISOString(),
    }));
  };

  const handleTimerValues = (e) => {
    const { name, value } = e.target;

    setTimerValues((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const hanleDays = (e, value) => {
    let days = repeatOptions.days || [];

    if (value === "workDays") {
      days = ["ma", "ti", "ke", "to", "pe"];
    } else if (value === "weekend") {
      days = ["la", "su"];
    } else if (value === "all") {
      days = ["ma", "ti", "ke", "to", "pe", "la", "su"];
    } else {
      if (days.includes(value)) {
        days = days.filter((el) => el !== value);
      } else {
        days = [...days, value];
      }
    }

    setRepeatOptions((state) => ({ ...state, days }));
  };

  const submitTimer = () => {
    let content = {
      layoutId: item._id,

      displayId: selectedDPId,
      socket: socket,
      orgID: selectedOrganization,

      ...timerValues,
    };

    if (repeatOptions?.active) {
      content = {
        ...content,
        ...repeatOptions,
      };
    }

    dispatch(actionSetScheduleToDisplay(content));
  };

  return (
    <div className=" LayoutCard">
      {!settingTimer ? (
        <div className="innerDiv">
          <div className="header">
            <div />
            <h2>{item.name}</h2>
            <div>
              <BasicDropdown ref={menuRef}>
                {fromOneDisplay ? (
                  <MenuItem
                    onClick={() => {
                      timerClicked();
                      menuRef?.current?.handleClose();
                    }}
                  >
                    <AlarmIcon /> Ajasta
                  </MenuItem>
                ) : null}
                <MenuItem
                  onClick={() => {
                    deleteLayout(item._id);
                    menuRef?.current?.handleClose();
                  }}
                >
                  <DeleteOutlineIcon /> Poista
                </MenuItem>
              </BasicDropdown>
            </div>
          </div>

          <div className="content">
            <p>
              <strong>Tyyppi:</strong> {converLayoutType(item.type) || "-"}
            </p>

            <div className="mapRow">
              <p>
                <strong>Käytössä näytöillä:</strong>{" "}
                {item.displays?.map((el, i) =>
                  i + 1 === item.displays.length || i === 0
                    ? el.name
                    : `, ${el.name}`
                )}
              </p>
            </div>
          </div>

          <div className="buttonRow" onClick={(e) => e.stopPropagation()}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={togglePreview}
            >
              Esikatselu
            </Button>

            <Button
              variant="outlined"
              color="secondary"
              onClick={() => onClick(item._id)}
            >
              Muokkaa
            </Button>
          </div>
          {fromOneDisplay ? (
            <div className={`footer ${isActive ? "footer-active" : ""}`}>
              {isActive ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={(e) => handleClick(e, item._id, true)}
                >
                  Käytössä nyt
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={(e) => handleClick(e, item._id, false)}
                >
                  Ota käyttöön
                </Button>
              )}
            </div>
          ) : null}
        </div>
      ) : (
        <div className="innerDiv">
          <div className="header">
            <div />
            <h2>{item.name}</h2>
            <div>
              <AlarmIcon onClick={timerClicked} />
            </div>
          </div>

          <div className="content">
            <div className="timer-content">
              <div className="row1">
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    label="Nimi ajastukselle"
                    variant="outlined"
                    onChange={handleTimerValues}
                    name="scheduleTitle"
                  />
                </FormControl>
              </div>

              <div className="row1">
                <FormControl fullWidth>
                  <MobileDateTimePicker
                    id="start"
                    label="Ajastus alkaa"
                    // disablePast
                    displayWeekNumber
                    name="startDate"
                    // onAccept={handleDate}
                    onAccept={(date) => handleDate("startDate", date)}
                  />
                </FormControl>
              </div>

              {timerValues?.startDate ? (
                <div className="row1">
                  <FormControl fullWidth>
                    <MobileDateTimePicker
                      //timezone="Europe/Helsinki"
                      id="end"
                      label="Ajastus loppuu"
                      // disablePast
                      displayWeekNumber
                      //  onAccept={handleDate}
                      onAccept={(date) => handleDate("endDate", date)}
                    />
                  </FormControl>
                </div>
              ) : null}

              <div className="row1">
                <FormControlLabel
                  control={<Checkbox />}
                  label="Toista"
                  name="active"
                  onChange={(e) => handleRepeatOptions(e)}
                  checked={repeatOptions?.active}
                  // disabled
                />
              </div>

              {repeatOptions?.active && (
                <div className="repeatDiv">
                  <div className="row2">
                    <div className="weekdaysChecks">
                      {weekdaysOpt.map((el) => (
                        <FormControlLabel
                          key={el.value}
                          control={<Checkbox />}
                          label={el.label}
                          name="days"
                          onChange={(e) => hanleDays(e, el.value)}
                          //onChange={(e) => handleRepeatOptions(e)}
                          checked={repeatOptions?.days?.includes(
                            el.value || false
                          )}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="row1">
                    <div className="weekDayBtn">
                      <Button
                        variant="outlined"
                        onClick={() => hanleDays(null, "all")}
                      >
                        Kaikki
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => hanleDays(null, "workDays")}
                      >
                        Arkipäivät
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => hanleDays(null, "weekend")}
                      >
                        Viikonloppu
                      </Button>
                    </div>
                  </div>
                  <div className="row2">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Viikot
                      </InputLabel>
                      <Select
                        value={repeatOptions?.weekType || "all"}
                        label="Viikot"
                        onChange={handleRepeatOptions}
                        name="weekType"
                      >
                        {scheduleWeekOpt.map((el, i) => (
                          <MenuItem key={i} value={el.value}>
                            {el.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              )}
              {fromOneDisplay ? (
                <div className="row1">
                  <small>
                    Näyttö ei tarkasta vielä päällekkäisiä ajastuksia!
                  </small>

                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={submitTimer}
                  >
                    Ajasta
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="popoverMain">
          <p>
            <strong>HUOM!</strong>{" "}
            <i>Jos näytöllä on jo asettelu käytössä, se korvataan</i>
          </p>

          {showCheck
            ? displays?.map((el, i) => (
                <FormControlLabel
                  key={i}
                  control={<Checkbox />}
                  label={el.name}
                  onChange={() => handleCheckDisplay(el)}
                  checked={selectedDisplays.some((el2) => el2._id === el._id)}
                  className={el._id === selectedDPId ? "activeDp" : ""}
                />
              ))
            : null}

          <Button
            variant="outlined"
            color="primary"
            onClick={() => submitDisplays(showCheck)}
          >
            Vahvista
          </Button>
        </div>
      </Popover>

      {openPreview ? (
        <DB
          usePreview={openPreview}
          preDB={
            topBar && bottomBar
              ? {
                  ...item,
                  bottomBar,
                  topBar,
                }
              : bottomBar
              ? {
                  ...item,
                  bottomBar,
                }
              : topBar
              ? {
                  ...item,
                  topBar,
                }
              : item
          }
          closePreview={togglePreview}
        />
      ) : null}
    </div>
  );
};

export default LayoutCard;

/* <FormControlLabel
          name="showWind"
          control={<Checkbox />}
          label="Tuuli"
          onChange={handleOptions}
          checked={states.options?.showWind}
        />*/
