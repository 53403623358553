import {
  Button,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { actionGetUserById, actionUpdateUser } from "../../store/winfo";
import { userRoleOpt } from "../../utils/options";

const OneUser = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const { oneUser } = useSelector((state) => state.winfo);
  const { data: myData } = useSelector((state) => state.user);

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    dispatch(actionGetUserById(userId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (oneUser) {
      setUserData(oneUser);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneUser]);

  const onChange = (e) => {
    setUserData((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  const submit = () => {
    dispatch(actionUpdateUser(userData));
  };

  if (!oneUser) {
    return null;
  }

  return (
    <div className="Page OneUser">
      <div className="MainContainer">
        <div className="div1">
          <div className="div1-left">
            <h1>KÄYTTÄJÄ: {userId}</h1>
          </div>
        </div>

        <div className="div2">
          <Grid container spacing={6} className="mainGrid">
            <Collapse in={userData ? true : false}>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  label="Nimi"
                  name="name"
                  variant="outlined"
                  onChange={onChange}
                  fullWidth
                  value={userData?.name || ""}
                  disabled={myData._id === userId}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  label="Käyttäjänimi"
                  name="username"
                  variant="outlined"
                  onChange={onChange}
                  fullWidth
                  value={userData?.username || ""}
                  disabled={myData._id === userId}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Käyttäjätaso
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={userData?.role || ""}
                    label="role"
                    onChange={onChange}
                    name="role"
                    disabled={myData._id === userId}
                  >
                    {userRoleOpt.map((el, i) => (
                      <MenuItem key={i} value={el.value}>
                        {el.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {myData._id !== userId ? (
                <Grid item xs={12}>
                  <Button onClick={submit} variant="contained" fullWidth>
                    Tallenna
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <p>Omaa tunnusta ei voi muokata</p>
                </Grid>
              )}
            </Collapse>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default OneUser;
