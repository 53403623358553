import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { handleResponses } from "../utils/handleResponses";
import proxy from "../utils/proxy";
import { actionOpenAlert } from "./alert";

// Slice
const slice = createSlice({
  name: "winfo",
  initialState: {
    organizations: null,
    foundUsers: null,
    oneUser: null,
    actionStatus: "",
    resData: undefined,
    ADMIN_MODE: localStorage.getItem("ADMIN_MODE") === "true",
    selectedOrganization: null,
  },
  reducers: {
    getOrganizations: (state, action) => {
      state.organizations = action.payload;
    },
    setFoundUsers: (state, action) => {
      state.foundUsers = action.payload;
    },
    setActionStatus: (state, action) => {
      state.actionStatus = action.payload;
    },
    setData: (state, action) => {
      state.resData = action.payload;
    },
    setOneUser: (state, action) => {
      state.oneUser = action.payload;
    },
    setAdminMode: (state, action) => {
      state.ADMIN_MODE = action.payload;
    },
    setOrganization: (state, action) => {
      state.selectedOrganization = action.payload;
    },
  },
});
export default slice.reducer;
// Actions
const {
  getOrganizations,
  setFoundUsers,
  setActionStatus,
  setData,
  setOneUser,
  setAdminMode,
  setOrganization,
} = slice.actions;

export const actionRegisterOrganization = ({ name }) => async (dispatch) => {
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    name,
  });

  try {
    const res = await axios.post(
      `${proxy}/api/register/organization`,
      body,
      config
    );

    const data = res.data;

    // antaa suoraan alert ilmoituksen
    dispatch(handleResponses(data));
  } catch (err) {
    const data = err;
    // antaa suoraan alert ilmoituksen
    dispatch(handleResponses(data));
  }
};

export const actionGetOrganizations = () => async (dispatch) => {
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(`${proxy}/api/winfo/get-organizations`, config);

    const data = res.data;

    dispatch(getOrganizations(data));
  } catch (err) {
    const data = err;
    // antaa suoraan alert ilmoituksen
    dispatch(handleResponses(data));
  }
};

export const actionGetAllUsers = ({ organizationId }) => async (dispatch) => {
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const body = JSON.stringify({
      organizationId,
    });

    const res = await axios.post(
      `${proxy}/api/winfo/get-all-users`,
      body,
      config
    );

    const data = res.data;

    dispatch(setFoundUsers(data));
  } catch (err) {
    const data = err;
    // antaa suoraan alert ilmoituksen
    dispatch(handleResponses(data));
  }
};

export const actionGetUserById = (userId) => async (dispatch) => {
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const body = JSON.stringify({
      userId,
    });

    const res = await axios.get(
      `${proxy}/api/winfo/get-user-by-id/${userId}`,
      body,
      config
    );

    const data = res.data;

    dispatch(setOneUser(data));
  } catch (err) {
    const data = err;
    // antaa suoraan alert ilmoituksen
    dispatch(handleResponses(data));
  }
};

export const actionUpdateUser = ({ _id, name, username, role }) => async (
  dispatch
) => {
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const body = JSON.stringify({
      name,
      username,
      role,
    });

    const res = await axios.post(
      `${proxy}/api/winfo/update-user/${_id}`,
      body,
      config
    );

    const data = res.data;

    dispatch(setOneUser(data));

    dispatch(
      actionOpenAlert({
        open: true,
        severity: "success",
        text: `"${data.name}" päivitetty`,
        timeout: 2000,
        autoHide: true,
      })
    );
  } catch (err) {
    const data = err;
    // antaa suoraan alert ilmoituksen
    dispatch(handleResponses(data));
  }
};

export const actionResetUserPW = (userId, socket, isBD) => async (dispatch) => {
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const body = JSON.stringify({
      userId,
    });

    const res = await axios.post(
      `${proxy}/api/winfo/reset-password`,
      body,
      config
    );

    const data = res.data;

    console.log("DATA", data);

    if (data && data.name) {
      dispatch(actionSetActionStatus("success"));

      dispatch(setData(data));

      dispatch(
        actionOpenAlert({
          open: true,
          severity: "success",
          text: `"${data.username}" salasana vaihdettu`,
          timeout: 2000,
          autoHide: true,
        })
      );

      if (socket && isBD) {
        const socketData = {
          to: [data._id],
          type: "REMOTE_LOGOUT_DB",
        };
       socket?.emit("DB_ACTION", socketData);
      }
    }
  } catch (err) {
    const data = err;
    // antaa suoraan alert ilmoituksen
    dispatch(handleResponses(data));
  }
};

export const actionSetActionStatus = (status) => (dispatch) => {
  dispatch(setActionStatus(status));
};

export const actionSetAdminMode = (mode) => (dispatch) => {
  dispatch(setAdminMode(mode));
};

export const actionSetSelectedOrganization = (org) => (dispatch) => {
  dispatch(setOrganization(org));
};
