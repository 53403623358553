import { useEffect, useState } from "react";
import "../styles/Pages.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  actionDeleteLayout,
  actionGetLayouts,
  actionNewLayout,
} from "../../store/layouts";
import { Button, CircularProgress, Grid, TextField } from "@mui/material";

import LayoutCard from "../../components/cards/LayoutCard";
import { useNavigate } from "react-router-dom";
import { ThreeCards } from "../../layouts/CardsLayouts";
import NoItems from "../../components/NoItems/NoItems";
import BasicStepperModal from "../../components/Modals/BasicStepperModal";
import { actionOpenAlert } from "../../store/alert";
import SelectLayoutType from "./SelectLayoutType";

const LayoutsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formStates, setFormStates] = useState();
  const [showNewForm, setShowNewForm] = useState(false);
  const [modalStepperIndex, setModalStepperIndex] = useState(0);

  const { layouts, loadingLayouts } = useSelector((state) => state.layout);
  const { selectedOrganization } = useSelector((state) => state.winfo);

  useEffect(() => {
    console.log("*****", selectedOrganization);
    const params = {
      orgID: selectedOrganization?._id,
    };

    dispatch(actionGetLayouts(params));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirect = (id) => {
    console.log("RE");
    navigate(id);
  };

  const onChange = (e) => {
    setFormStates((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  const deleteLayout = (id) => {
    const content = {
      layoutId: id,
      orgID: selectedOrganization?._id,
    };
    console.log("DEL", id);

    dispatch(actionDeleteLayout(content));
  };

  const selectLayout = (cardsPcs, type) => {
    console.log("SELEC", cardsPcs, type);
    setFormStates((state) => ({
      ...state,
      cardsPcs: cardsPcs,
      type: type,
    }));
  };

  const modalContent =
    modalStepperIndex === 0 ? (
      <div className="modal-content">
        <p>Anna nimi uudelle asettelulle</p>
        <TextField
          id="outlined-basic"
          label="Nimi"
          name="name"
          variant="outlined"
          onChange={onChange}
          value={formStates?.name || ""}
          autoFocus
        />
      </div>
    ) : modalStepperIndex === 1 ? (
      <div className="rotationSelectRoot">
        <p>
          Tuleeko asettelu pysty- vai vaakanäytölle? Tätä asetusta ei voi
          muuttaa myöhemmin
        </p>
        <div className="rotationSelectDiv">
          <button
            className={`box ${
              formStates?.orientation === 0 ? "activeBox" : ""
            }`}
            onClick={() =>
              setFormStates((state) => ({ ...state, orientation: 0 }))
            }
          >
            VAAKA
          </button>
          <button
            className={`box ${
              formStates?.orientation === 90 ? "activeBox" : ""
            }`}
            onClick={() =>
              setFormStates((state) => ({ ...state, orientation: 90 }))
            }
          >
            PYSTY
          </button>
        </div>
      </div>
    ) : (
      <div>
        <p>
          Montako korttia ja miten ne halutaan näyttää näytöllä?{" "}
          <i>Tätä asetusta ei voi muuttaa myöhemmin</i>
        </p>
        <SelectLayoutType
          showType="flex"
          orientation={formStates?.orientation}
          selectLayout={selectLayout}
          type={formStates?.type}
        />
      </div>
    );

  const nextStep = () => {
    if (modalStepperIndex === 0 && !formStates?.name) {
      return dispatch(
        actionOpenAlert({
          open: true,
          severity: "warning",
          text: `Nimi on pakollinen`,
          timeout: 2000,
          autoHide: true,
        })
      );
    } else if (modalStepperIndex === 1) {
      console.log("form", formStates?.orientation === 90);
      if (formStates?.orientation !== 0 && formStates?.orientation !== 90)
        return dispatch(
          actionOpenAlert({
            open: true,
            severity: "warning",
            text: `Suunta on pakollinen`,
            timeout: 2000,
            autoHide: true,
          })
        );
    }

    setModalStepperIndex((state) => (state += 1));
  };

  const prevStep = () => {
    setModalStepperIndex((state) => (state -= 1));
  };

  const modalFinal = () => {
    const data = {
      ...formStates,
      orgID: selectedOrganization?._id,
    };
    dispatch(actionNewLayout(data));
    setShowNewForm(false);
  };

  return (
    <div className="Page LayoutsPage">
      <div className="MainContainer">
        <div className="div1">
          <div className="div1-left">
            <h1>Asettelut</h1>
          </div>

          <div className="div1-right">
            <Button
              variant="contained"
              onClick={() => setShowNewForm(!showNewForm)}
            >
              Luo uusi
            </Button>
          </div>
        </div>

        <BasicStepperModal
          open={showNewForm}
          title="Uusi asettelu"
          nextStep={nextStep}
          steps={["Nimi", "Suunta", "Tyyppi"]}
          activeStep={modalStepperIndex}
          finalAction={modalFinal}
          formStates={formStates}
          prevStep={prevStep}
          onClose={() => setShowNewForm(false)}
        >
          {modalContent}
        </BasicStepperModal>

        <div className="div2">
          {loadingLayouts ? (
            <CircularProgress color="inherit" />
          ) : layouts && layouts.length ? (
            <ThreeCards>
              {layouts.map((el, i) => (
                <Grid item key={i} xs={12} xl={4} md={6}>
                  <LayoutCard
                    item={el}
                    key={i}
                    onClick={() => redirect(el._id)}
                    deleteLayout={deleteLayout}
                    fromOneDisplay={false}
                  />
                </Grid>
              ))}
            </ThreeCards>
          ) : (
            <NoItems
              title="Asetteluja ei löytynyt!"
              text="Asetteluja pääset luomaan itse sivun ylälaidasta"
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default LayoutsPage;

/* <div className="BasicPage Layouts">
      <div className="BasicHeaderDiv">
        <h1>Asettelu</h1>
        <Button variant="contained" onClick={() => setShowNewForm(!showNewForm)}>Luo uusi</Button>
      </div>
      <Collapse in={showNewForm}>{addForm}</Collapse>

      <div className="layoutsDiv">
        {layouts?.map((el, i) => (
          <LayoutCard item={el} key={i} onClick={() => redirect(el._id)} />
        ))}
      </div>
    </div>*/
