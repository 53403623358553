import { Navigate } from "react-router-dom";

export const OnlyLoginNeeded = ({
  userLoaded,
  isLoggedIn,
  DBLoggedIn,
  children,
}) => {
  if (DBLoggedIn) {
    return <Navigate to="/db" replace />;
  }
  if (!userLoaded) {
    return null;
  }
  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export const DBLoginNeeded = ({ DBLoggedIn, DBLoaded, children }) => {
  if (DBLoaded && !DBLoggedIn) {
    return <Navigate to="/DB/login" replace />;
  }

  return children;
};

export const RoleNeeded = ({
  userLoaded,
  isLoggedIn,
  children,
  allowedRoles,
  userRole,
}) => {
  if (!userLoaded) {
    return null;
  }
  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  if (!allowedRoles.some((el) => el === userRole)) {
    return <Navigate to="/" replace />;
  }

  return children;
};
