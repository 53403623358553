import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import proxy from "../utils/proxy";
import { actionOpenAlert } from "./alert";

// Slice
const slice = createSlice({
  name: "fileBank",
  initialState: { files: [], loadingFiles: true },
  reducers: {
    uploadFile: (state, action) => {
      state.files = [action.payload, ...state.files];
    },
    getFiles: (state, action) => {
      state.files = action.payload;
      state.loadingFiles = false;
    },

    removeFile: (state, action) => {
      state.files = state.files.filter(
        (el) => el._id.toString() !== action.payload
      );
    },
  },
});
export default slice.reducer;

// Actions
const { uploadFile, getFiles, removeFile } = slice.actions;

export const actionUploadFileToBank = ({
  url,
  publicId,
  thumbnail,
  format,
  orgID,
}) => async (dispatch) => {
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const body = JSON.stringify({
      url,
      publicId,
      thumbnail,
      format,
      orgID,
    });

    const response = await axios.post(
      `${proxy}/api/file-bank/upload`,
      body,
      config
    );
    const data = response.data;

    dispatch(uploadFile(data));
  } catch (err) {
    console.log("VIRHE upload file:", err.message);
  }
};

export const actionGetFiles = (params) => async (dispatch) => {
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.get(
      `${proxy}/api/file-bank/get-files?orgID=${params?.orgID || ""}`,
      config
    );
    const data = response.data;

    dispatch(getFiles(data));
  } catch (err) {
    console.log("VIRHE Ladattaessa tiedostoja:", err.message);
  }
};

export const actionDeleteFile = ({ fileBankId, publicId }) => async (
  dispatch
) => {
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  // ei ole backillä orgID tarkistusta
  try {
    const body = JSON.stringify({
      publicId,
    });

    const response = await axios.put(
      `${proxy}/api/file-bank/${fileBankId}/delete`,
      body,
      config
    );
    const data = response.data;

    console.log("file", data);

    if (data) {
      dispatch(removeFile(data._id));
      dispatch(
        actionOpenAlert({
          open: true,
          severity: "success",
          text: `Poistettu`,
          timeout: 2000,
          autoHide: true,
        })
      );
    }
  } catch (err) {
    console.log("VIRHE delete file:", err.message);
  }
};

export const actionGenerateSignedUrl = (params_to_sign) => async (dispatch) => {
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const body = JSON.stringify({
      params_to_sign,
    });

    const response = await axios.post(
      `${proxy}/api/file-bank/generate-signed-url`,
      body,
      config
    );
    const data = response.data;

    console.log("* DATA *", data);

    return data;
  } catch (err) {
    console.log("VIRHE upload file:", err.message);
  }
};
