import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { actionGetDisplays } from "../../store/display";

const SelectDisplays = ({ value, setDisplays }) => {
  const dispatch = useDispatch();
  const { displays } = useSelector((state) => state.display);

  useEffect(() => {
    if (!displays?.length) {
      dispatch(actionGetDisplays());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const data = e.map((el) => {
      return {
        name: el.name,
        _id: el._id,
      };
    });
    setDisplays(data);
  };

  // value handleChange, options perus
  //getLabel ja getValue - Mäkä kenttä on options arrayssa
  return (
    <Select
      value={value}
      onChange={handleChange}
      options={displays}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option._id}
      // z-index proble fix?
      menuPortalTarget={document.body}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      isMulti={true}
    />
  );
};

export default SelectDisplays;
