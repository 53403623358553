import { actionOpenAlert } from "../store/alert";

export const handleResponses = (data) => (dispatch) => {
  const { success } = data;

  if (success) {
    const { severity, msg } = data;

    dispatch(
      actionOpenAlert({
        open: true,
        severity: severity,
        text: `${msg}`,
        timeout: 5000,
        autoHide: true,
      })
    );
  } else {
    const { severity, msg, errMsg } = data.response?.data;

    dispatch(
      actionOpenAlert({
        open: true,
        severity: severity,
        text: `${data.response?.status || ""} - "${msg}" ${
          errMsg ? `- "${errMsg}"` : ""
        }`,
        timeout: 5000,
        autoHide: true,
      })
    );
  }
};
