import "../styles/NoItems.scss";

const NoItems = ({ title, text, children }) => {
  return (
    <div className="NoItems">
      <h1>{title}</h1>

      <p>{text}</p>

      <div className="childrenDiv">{children}</div>
    </div>
  );
};

export default NoItems;
