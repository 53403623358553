const converLayoutType = (type) => {
  let temp = type;

  if (temp === "full") {
    temp = "Kokonäyttö";
  } else if (temp === "side-by-side") {
    temp = "Vierekkäin";
  } else if (temp === "up-down") {
    temp = "Alekkain";
  } else if (temp === "2x2") {
    temp = "2x2";
  }

  return temp;
};

export default converLayoutType;
