import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actionGetDisplayData,
  actionRefreshLoginDB,
  actionSetDisplayData,
} from "../../store/DB";
import DBContent from "./DBContent";

const DBWithLogin = ({ usePreview, preDB, closePreview }) => {
  const dispatch = useDispatch();

  const { DB, DBLoggedIn, DBLoginData } = useSelector((state) => state.DB);

  useEffect(() => {
    if (!usePreview) {
      // const data = JSON.parse(localStorage.getItem("DB_offline")) || {};
      //  dispatch(actionSetDisplayData(data));

      dispatch(actionRefreshLoginDB());
    } else {
      dispatch(actionSetDisplayData(preDB));
    }
  }, []);

  useEffect(() => {
    let interval;
    if (!usePreview && DBLoggedIn) {
      dispatch(actionGetDisplayData());

      interval = setInterval(() => {
        dispatch(actionGetDisplayData());
      }, 10 * 60 * 1000);
    }

    return () => clearInterval(interval);
  }, [DBLoggedIn]);

  useEffect(() => {
    if (DB && DB.name) {
      // jos saatu ladattua DB objecti, asetetaan se muistiin offlinea varten
      localStorage.setItem("DB_offline", JSON.stringify(DB));
    }
  }, [DB]);

  return (
    <div className="RootRoot">
      {DB && DB.name ? (
        <DBContent
          DB={DB}
          DBLoginData={DBLoginData}
          usePreview={usePreview}
          closePreview={closePreview}
        />
      ) : (
        <p>LATAA</p>
      )}
    </div>
  );
};

export default DBWithLogin;
