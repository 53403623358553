import { CircularProgress } from "@mui/material";
import { useState } from "react";
import { useCallback, useEffect } from "react";
import { getRss } from "../../utils/getRss";

import { FormatDate } from "../../utils/dates";
//import logo from "../../images/Yle-Uutiset-valkoinen.png"
import { AutoTextSize } from "auto-text-size";

const NewsCard = ({ feedUrl, options, styles, intervalSpeed }) => {
  // 10min
  const int = 1000 * 60 * 10;
  let interval;

  const [news, setNews] = useState(null);
  const [newsIndex, setNewsIndex] = useState(0);

  const rssFeed = useCallback(async () => {
    const date = FormatDate(new Date(), "DD/MM - HH:mm:ss");
    const x = await getRss(feedUrl);
    setNews(x);
    setNewsIndex(0);
  }, [news]);

  useEffect(() => {
    if (!news) {
      // haetaan ekan kerran uutiset (interval muuten odottaa yhden syklin)
      rssFeed();
    }

    interval = setInterval(() => {
      rssFeed();
    }, int);

    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let interval;

    const speed = intervalSpeed || 10000;
    interval = setInterval(() => {
      const max = news?.length - 1 || 15;
      if (newsIndex < max) {
        setNewsIndex(newsIndex + 1);
      } else {
        setNewsIndex(0);
      }
    }, speed);

    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsIndex]);

  if (!news?.length) {
    return (
      <div className="NewsCard">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div
      className="NewsCard"
      style={{
        borderStyle: "solid",
        borderWidth: "2xp",
        borderColor: styles?.borderColor || "black",
      }}
    >
      <div className="mainContent">
        <div className="header">
          <AutoTextSize mode="oneline" maxFontSizePx={42}>
            Yle uutiset
          </AutoTextSize>
        </div>
        <div className="footer">
          <AutoTextSize mode="box" className="autoSize">
            {news[newsIndex].title}
          </AutoTextSize>
        </div>
        {options?.showNewsDesc && (
          <div className="content">
            <AutoTextSize mode="box">
              {news[newsIndex].description}
            </AutoTextSize>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewsCard;
//      {news[newsIndex].imgSrc ? <img src={news[newsIndex].imgSrc} /> : null}
