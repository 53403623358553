import { createSlice } from "@reduxjs/toolkit";

// Slice
const slice = createSlice({
  name: "organization",
  initialState: {
    online: {},
  },
  reducers: {
    setOnline: (state, action) => {
      state.online = action.payload;
    },
  },
});
export default slice.reducer;
// Actions
const { setOnline } = slice.actions;

export const actionSetOnline = (data) => (dispatch) => {
  dispatch(setOnline(data));
};
