import { useSelector } from "react-redux";

const OnlyIfAdminMode = ({ children }) => {
  const { ADMIN_MODE } = useSelector((state) => state.winfo);

  if (!ADMIN_MODE) {
    return null;
  }

  return children;
};

export default OnlyIfAdminMode;
