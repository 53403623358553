import { Button, Collapse, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { actionDBLogout } from "../../store/DB";
import { useDispatch, useSelector } from "react-redux";

import getLocalIp from "../../utils/getLocalIp";
import { FormatDate } from "../../utils/dates";

const DBPinCode = ({
  askPin,
  DBLoginData,
  resetCornerClicks,
  enterPressed,
  setEnterPressed,
  doSpeedTest,
  setDoSpeedTest,
  DB,
}) => {
  const dispatch = useDispatch();
  const idName = process.env.REACT_APP_DB_ID_NAME_LOCALSTORAGE;

  const { networkStatus } = useSelector((state) => state.appData);

  const [enteredPin, setEnteredPin] = useState("");
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    return () => {
      setEnterPressed(false);
    };
  }, []);

  useEffect(() => {
    confirmPinCode();
  }, [enterPressed]);

  const confirmPinCode = () => {
    console.log("enter", enteredPin);
    if (process.env.REACT_APP_EXIT_PIN === enteredPin) {
      handleLogout();
    } else if (enteredPin === "0000") {
      console.log("enter", enteredPin);

      setShowInfo(true);
    }
  };

  const handleLogout = () => {
    dispatch(actionDBLogout(DBLoginData?.token));
  };

  if (!askPin) return null;

  console.log("PRESSED", DB);

  return (
    <div className="askPinRoot">
      <div className="askPinDiv">
        <div className="div1">
          <TextField
            className="text"
            value={enteredPin}
            placeholder="pin..."
            onChange={(e) => {
              setEnteredPin(e.target.value);
            }}
            autoFocus
          />

          <div className="buttons">
            <Button variant="outlined" color="primary" onClick={confirmPinCode}>
              Vahvista
            </Button>

            <Button
              variant="outlined"
              color="secondary"
              onClick={resetCornerClicks}
            >
              Peruuta
            </Button>
          </div>
        </div>

        <Collapse in={showInfo} className="InfoRoot">
          <div className="infoDiv">
            <h2>TIETOJA</h2>

            <div className="infoBDiv2">
              <div className="infoRow">
                <Button
                  variant="outlined"
                  onClick={() => {
                    localStorage.removeItem(idName);
                    window.location.reload();
                  }}
                >
                  Tyhjennä deviceId
                </Button>
              </div>
              <div className="infoRow">
                <strong> Verkon tila:</strong>
                {networkStatus ? (
                  <span className="online">ONLINE</span>
                ) : (
                  <span className="offline">OFFLINE</span>
                )}
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setDoSpeedTest((state) => !state)}
                >
                  Nopeustesti
                </Button>
              </div>
              {doSpeedTest && <iframe src="https://fast.com" />}

              <div className="infoRow">
                <strong>Origin:</strong> {window.location.origin}
              </div>
              <div className="infoRow">
                <strong>Href:</strong> {window.location.href}
              </div>

              <div className="infoRow">
                <strong>Token haettu:</strong>{" "}
                {FormatDate(
                  DB?.timestamps?.lastTokenFetch,
                  "dd DD.MM.yyyy HH:mm:ss"
                )}
              </div>

              <div className="infoRow">
                <strong>Viimeinen kirjautuminen: </strong>{" "}
                {FormatDate(
                  DB?.timestamps?.lastLogin,
                  "dd DD.MM.yyyy HH:mm:ss"
                )}
              </div>

              <div className="infoRow">
                <strong>Data haettu: </strong>{" "}
                {FormatDate(DB?.timestamps?.lastGet, "dd DD.MM.yyyy HH:mm:ss")}
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default DBPinCode;
