import React from "react";
import useQueryParam from "../../utils/UseQueryParams";

import "../styles/DB.scss";

import DBWithLogin from "./DBWithLogin";
import DBUrl from "./DBUrl";
//useAuth

const DB = ({ usePreview, preDB, closePreview }) => {
  const [useAuth] = useQueryParam("useAuth");
  // katsotaa url halutaanko käyttää kirjautumiosta vai ns. avointa näyttöä (url)
  const useLogin = useAuth === "true";

  // console.log("* USE AUTH *", useLogin,);

  if (useLogin) {
    return (
      <DBWithLogin
        usePreview={usePreview}
        preDB={preDB}
        closePreview={closePreview}
      />
    );
  } else {
    return (
      <DBUrl
        usePreview={usePreview}
        preDB={preDB}
        closePreview={closePreview}
      />
    );
  }
};

export default DB;
