import { useDispatch, useSelector } from "react-redux";
import "./styles/Pages.scss";
import { Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import { actionLogin } from "../store/user";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";

export default function LoginPage() {
  const dispatch = useDispatch();
  const inputRef = useRef();

  const { token, isLoggedIn } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: (values) => {
      dispatch(actionLogin(values));
    },
  });

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();

        if (formik.values.username) {
          if (!formik.values.password || formik.values.password === "") {
            // Siirretään focus salasana kenttään
            console.log("Enter key was pressed. Run your function.");
            inputRef.current.focus();
          } else if (formik.values.password) {
            const values = {
              username: formik.values.username,
              password: formik.values.password,
            };
            dispatch(actionLogin(values));
          }
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [formik.values]);

  useEffect(() => {
    if (isLoggedIn && token) {
      navigate("/");
    }
  }, [token]);

  return (
    <div className="Page Login">
      <div className="MainContainer">
        <div className="div1">
          <div className="div1-left">
            <h1>KIRJAUDU</h1>
          </div>
        </div>
        <div className="div2">
          <div className="loginForm">
            <TextField
              id="outlined-basic"
              label="Käyttäjänimi"
              type="text"
              name="username"
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
              autoFocus
            />

            <TextField
              id="outlined-basic"
              label="Salasana"
              name="password"
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
              type="password"
              inputRef={inputRef}
            />

            <Button onClick={formik.handleSubmit} variant="outlined" fullWidth>
              Kirjaudu
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
