import { Button, Collapse, TextField } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Notification from "../../components/Notifications/Notification";
import SelectDisplays from "../../components/Select/SelectDisplays";
import {
  actionGetNotifications,
  actionNewNotification,
  actionSetNotification,
} from "../../store/notification";
import { AddToDate } from "../../utils/dates";
import "../styles/Pages.scss";

const Notifications = ({ socket }) => {
  const dispatch = useDispatch();

  const [newNotification, setNewNotification] = useState(false);
  const [formStates, setFormStates] = useState(null);
  const [selectedDisplays, setSelectedDisplays] = useState([]);
  const { notifications } = useSelector((state) => state.notification);

  useEffect(() => {
    dispatch(actionGetNotifications());
  }, []);

  const setNotification = (title, desc, url, time, displays) => {
    const now = Date.now();
    const removeAt = AddToDate(now, time, "seconds", "x");
    const data = {
      title,
      desc,
      displays: displays,
      socket,
      createdAt: now,
      url: url,
      removeAt,
    };

    console.log("DATA", data);
    dispatch(actionSetNotification(data));
  };

  const onChange = (e) => {
    setFormStates((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  const submit = () => {
    const now = Date.now();
    const data = {
      ...formStates,
      createdAt: now,
      //  removeAfter: AddToDate(now, formStates.removeAfter, "s", "x"),
      displays: selectedDisplays,
      url:
        "https://res.cloudinary.com/delccokng/image/upload/v1659610728/files/xi0upunoy44q0oxmyuil.png",
    };

    dispatch(actionNewNotification(data));
  };

  const addForm = (
    <div className="newForm">
      <h2>Lisää</h2>

      <TextField
        id="outlined-basic"
        label="Otsikko"
        name="title"
        variant="outlined"
        onChange={onChange}
        value={formStates?.title || ""}
      />

      <TextField
        id="outlined-basic"
        label="Kuvaus"
        name="desc"
        variant="outlined"
        onChange={onChange}
        value={formStates?.desc || ""}
      />

      <p>Valitse näytöt</p>
      <SelectDisplays
        setDisplays={setSelectedDisplays}
        value={selectedDisplays}
      />

      <TextField
        id="outlined-basic"
        label="Aika (s)"
        name="removeAfter"
        variant="outlined"
        onChange={onChange}
        value={formStates?.removeAfter || ""}
      />

      <Button onClick={submit} variant="contained" color="primary">
        Tallenna
      </Button>
    </div>
  );

  return (
    <div className="BasicPage Notifications">
      <div className="HeaderDiv">
        <h1>Ilmoitukset</h1>
        <Button
          variant="contained"
          onClick={() => setNewNotification(!newNotification)}
        >
          Uusi
        </Button>
      </div>
      <Collapse in={newNotification}>{addForm} </Collapse>

      <hr />

      {notifications?.length ? (
        <div className="notificationsDiv">
          {notifications.map((el, i) => (
            <div
              className="notifDiv"
              key={i}
              onClick={() =>
                setNotification(el.title, el.desc, el.url, el.removeAfter, el.displays)
              }
            >
              <Notification item={el} key={i} />
            </div>
          ))}
        </div>
      ) : (
        <p>Ei luotuja</p>
      )}
    </div>
  );
};

export default Notifications;
