import React, { useRef } from "react";

const VideoCard = React.forwardRef(({ item, styles }, ref) => {
  const videoRef = useRef();

  const handleLoadedMetadata = () => {
    const video = videoRef.current;
    console.log('VIDEO', item.files[0])

    if (!video) return;
    console.log(`The video is ${video.duration} seconds long.`);
  };

  return (
    <div className="VideoCard" ref={ref}>
      <div>
        {item.files?.length ? (
          <video
            src={item.files[0].url}
            autoPlay
            loop
            muted
            ref={videoRef}
            onLoadedMetadata={handleLoadedMetadata}
          />
        ) : (
          <p>Ei videota</p>
        )}
      </div>
    </div>
  );
});

export default VideoCard;
/*
      {item.files?.length ? <video src={item.files[0]} autoPlay loop controls muted/> : <p>Ei videota</p>}

      */
