import { useDispatch, useSelector } from "react-redux";
import { actionAnswerModal, actionCloseModal } from "../../store/modal";
import ConfirmModal from "./ConfirmModal";

const Modals = () => {
  const dispatch = useDispatch();

  const { modalType, open, title, content } = useSelector(
    (state) => state.modal
  );

  const handleClose = () => {
    dispatch(actionCloseModal());
  };

  const answerModal = (answer) => {
    dispatch(actionAnswerModal(answer));
  };

  switch (modalType) {
    case 1:
      return (
        <ConfirmModal
          open={open}
          title={title}
          content={content}
          handleClose={handleClose}
          answerModal={answerModal}
        />
      );

    default:
      return null;
  }
};

export default Modals;
