import svgFull1 from "../images/9.svg";
import svgSide1 from "../images/7.svg";
import svgSide2 from "../images/8.svg";
import svgUp1 from "../images/6.svg";
import svgUp2 from "../images/5.svg";

import svg2x21 from "../images/1.svg";
import svg2x22 from "../images/2.svg";
import svg2x23 from "../images/3.svg";
import svg2x24 from "../images/4.svg";

export default function checkCardSvg(type, index) {
  if (type === "full") {
    return svgFull1;
  } else if (type === "side-by-side") {
    if (index === 0) {
      return svgSide1;
    } else return svgSide2;
  } else if (type === "up-down") {
    if (index === 0) {
      return svgUp1;
    } else return svgUp2;
  } else if (type === "2x2") {
    if (index === 0) {
      return svg2x21;
    } else if (index === 1) {
      return svg2x22;
    } else if (index === 2) {
      return svg2x23;
    } else if (index === 3) {
      return svg2x24;
    }
  }
}
