import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { AppBar, Divider, Switch } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actionLogout } from "../../store/user";
import ROLES from "../../utils/roles";
import {
  actionSetAdminMode,
  actionSetSelectedOrganization,
} from "../../store/winfo";
import BasicSelect from "../Select/BasicSelect";
import OnlyForWinfo from "../Winfo/OnlyForWinfo";
import OnlyIfAdminMode from "../Winfo/OnlyIfAdminMode";

const pages = [
  { label: "Etusivu", url: "/", roles: [ROLES.user, ROLES.admin, ROLES.winfo] },
  {
    label: "Näytöt",
    url: "/displays",
    roles: [ROLES.user, ROLES.admin, ROLES.winfo],
  },
  {
    label: "Asettelu",
    url: "/layouts",
    roles: [ROLES.user, ROLES.admin, ROLES.winfo],
  },
  /*{
    label: "Kortit",
    url: "/cards",
    roles: [ROLES.user, ROLES.admin, ROLES.winfo],
  },*/
  {
    label: "Pankki",
    url: "/upload",
    roles: [ROLES.user, ROLES.admin, ROLES.winfo],
  },
  /*{
    label: "Ilmoitukset",
    url: "/notifications",
    roles: [ROLES.user, ROLES.admin, ROLES.winfo],
  },*/
  { label: "* WINFO *", url: "/register", roles: [ROLES.winfo] },
];
const versionNumber = process.env.REACT_APP_VERSION;

const settings = [
  { label: versionNumber, disabled: true },

  { label: "Kirjaudu ulos", action: "logout" },
  { label: "Minun tili", action: "my-account" },
];

const AppbarLoggedIn = () => {
  const dispatch = useDispatch();
  const {
    token,
    data: { role },
  } = useSelector((state) => state.user);
  const { showAppbar } = useSelector((state) => state.appData);
  const { ADMIN_MODE, organizations, selectedOrganization } = useSelector(
    (state) => state.winfo
  );

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (url) => {
    if (url) {
      navigate(url);
    }
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (action) => {
    if (action === "logout") {
      dispatch(actionLogout(token));
      navigate("/login");
      window.location.reload();
    } else if (action === "my-account") {
      navigate(`/${action}`);
    }
    setAnchorElUser(null);
  };

  const handleSwitch = (e) => {
    const { name, checked } = e.target;
    localStorage.setItem(name, checked);
    dispatch(actionSetAdminMode(checked));

    if (!checked) {
      // kun admin mode otetaan pois päältä
      actionSetSelectedOrganization(null);
      localStorage.removeItem("selectedOrganization");
      window.location.reload();
    }
  };

  const handleChange = (selectedOption) => {
    dispatch(actionSetSelectedOrganization(selectedOption));
    localStorage.setItem(
      "selectedOrganization",
      JSON.stringify(selectedOption)
    );
    window.location.reload();

    // dispatch(actionSetInitialsReady(false));
    // dispatch(actionSetDisplays([], true));
  };

  if (!showAppbar) {
    return null;
  }

  return (
    <AppBar position="static" className="Appbar">
      <Container maxWidth="xl">
        <Toolbar disableGutters variant="dense">
          {/* <img src={logo} alt="logo" />*/}

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map(
                (page) =>
                  page.roles.includes(role) && (
                    <MenuItem
                      key={page.label}
                      onClick={() => handleCloseNavMenu(page.url)}
                    >
                      <Typography textAlign="center">{page.label}</Typography>
                    </MenuItem>
                  )
              )}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map(
              (page) =>
                page.roles.includes(role) && (
                  <Button
                    key={page.label}
                    onClick={() => handleCloseNavMenu(page.url)}
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    {page.label}
                  </Button>
                )
            )}
          </Box>

          <OnlyForWinfo>
            <OnlyIfAdminMode>
              <Box>
                <BasicSelect
                  value={selectedOrganization}
                  handleChange={handleChange}
                  options={organizations}
                  getLabel="name"
                  getValue="_id"
                  isMulti={false}
                  placeholder="Valitse organisaatio..."
                />
              </Box>
            </OnlyIfAdminMode>
          </OnlyForWinfo>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting.label}
                  onClick={() => handleCloseUserMenu(setting.action)}
                  disabled={setting.disabled || false}
                >
                  <Typography textAlign="center">{setting.label}</Typography>
                </MenuItem>
              ))}

              <Divider />
              {role === "winfo" ? (
                <MenuItem>
                  <p>ADMIN</p>
                  <Switch
                    name="ADMIN_MODE"
                    color="secondary"
                    checked={ADMIN_MODE}
                    onChange={handleSwitch}
                  />
                </MenuItem>
              ) : null}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default AppbarLoggedIn;
