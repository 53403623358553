import { createSlice } from "@reduxjs/toolkit";

// Slice
const slice = createSlice({
  name: "alert",
  initialState: {
    open: false,
    text: "",
    severity: "",
  },
  reducers: {
    openAlert: (state, action) => {
      state.open = action.payload.open;
      state.text = action.payload.text;
      state.severity = action.payload.severity;
      state.timeout = action.payload.timeout;
      state.variant = action.payload.variant;
      state.autoHide = action.payload.autoHide;
    },
  },
});
export default slice.reducer;
// Actions
const { openAlert } = slice.actions;

export const actionOpenAlert = ({
  open,
  text,
  severity,
  timeout,
  variant,
  autoHide,
}) => (dispatch) => {
  dispatch(openAlert({ open, text, severity, timeout, variant, autoHide }));
};

export const actionCloseAlert = () => (dispatch) => {
  dispatch(openAlert({ open: false, text: "", severity: "info" }));
};
