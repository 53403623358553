import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import user from "./user";
import alert from "./alert";
import display from "./display";
import modal from "./modal";
import winfo from "./winfo";
import DB from "./DB";
import appData from "./appData";
import organization from "./organization";
import fileBank from "./fileBank";
import notification from "./notification";
import toast from "./toast";
import layout from "./layouts";
import socket from "./socket";

const reducer = combineReducers({
  alert,
  modal,
  user,
  organization,
  display,
  layout,
  winfo,
  DB,
  appData,
  fileBank,
  notification,
  toast,
  socket,
});

const store = configureStore({
  reducer,
});
export default store;
