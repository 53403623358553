import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import proxy from "../utils/proxy";
import { actionOpenAlert } from "./alert";
import { handleResponses } from "../utils/handleResponses";

// Slice
const slice = createSlice({
  name: "layout",
  initialState: { layouts: null, loadingLayouts: true, oneLayout: null },
  reducers: {
    getLayouts: (state, action) => {
      state.layouts = action.payload;
      state.loadingLayouts = false;
    },
    getLayoutById: (state, action) => {
      state.oneLayout = action.payload;
    },
    newLayout: (state, action) => {
      state.layouts = [action.payload, ...state.layouts];
    },

    updateLayout: (state, action) => {
      console.log("RED", action.payload);
      state.oneLayout = {
        ...state.oneLayout,
        ...action.payload,
      };
    },

    updateLayous: (state, action) => {
      state.layouts = state.layouts.map((el) =>
        el._id === action.payload._id ? { ...el, ...action.payload } : el
      );
    },

    deleteLayout: (state, action) => {
      state.layouts = state.layouts.filter(
        (el) => el._id !== action.payload._id
      );
    },

    setInitials: (state, action) => {
      state.layouts = null;
      state.loadingLayouts = true;
      state.oneLayout = null;
    },
  },
});
export default slice.reducer;

// Actions
const {
  getLayouts,
  newLayout,
  getLayoutById,
  updateLayout,
  updateLayous,
  deleteLayout,
  setInitials,
} = slice.actions;

export const actionGetLayouts = (params) => async (dispatch) => {
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.get(
      `${proxy}/api/layouts/get-org-layouts?orientation=${
        params?.orientation
      }&orgID=${params?.orgID || ""}`,
      config
    );
    const data = response.data;

    dispatch(getLayouts(data));
  } catch (err) {
    console.log("VIRHE Ladattaessa asetteluja:", err.message);
  }
};

export const actionNewLayout = ({ name, orientation, type, orgID }) => async (
  dispatch
) => {
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const body = JSON.stringify({
      name,
      orientation,
      type,
      orgID,
    });
    const response = await axios.post(
      `${proxy}/api/layouts/new-layout`,
      body,
      config
    );
    const data = response.data;

    dispatch(newLayout(data));

    dispatch(
      actionOpenAlert({
        open: true,
        severity: "success",
        text: `Onnistui`,
        timeout: 2000,
        autoHide: true,
      })
    );
  } catch (err) {
    console.log("VIRHE Luodessa asetteluja:", err.message);
  }
};

export const actionGetLayoutById = (params) => async (dispatch) => {
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.get(
      `${proxy}/api/layouts/get-layout-by-id/${params?.layoutId}?orgID=${
        params?.orgID || ""
      }`,
      config
    );
    const data = response.data;

    dispatch(getLayoutById(data));
  } catch (err) {
    console.log("VIRHE Ladattaessa asetteluja:", err);

    dispatch(
      actionOpenAlert({
        open: true,
        severity: "error",
        text: `Epäonnistui (${err.response?.data?.errMsg})`,
        timeout: 2000,
        autoHide: false,
      })
    );
  }
};

export const actionUpdateLayout = ({
  cardsPcs,
  type,
  orientation,
  selectedCards,
  layoutId,
  selectedDisplays,
  socket,
  styles,
  updateLayouts,
  name,
  orgID,
}) => async (dispatch) => {
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const body = JSON.stringify({
      layoutId,
      cardsPcs,
      type,
      orientation,
      selectedCards,
      selectedDisplays,
      styles,
      name,
      orgID,
    });

    const response = await axios.put(
      `${proxy}/api/layouts/update-layout`,
      body,
      config
    );
    const data = response.data;

    const socketData = {
      to: data.displays.map((el) => el._id),
    };

   socket?.emit("DB_SOCKET", socketData);
    dispatch(
      actionOpenAlert({
        open: true,
        severity: "success",
        text: `Päivitys onnistui`,
        timeout: 2000,
        autoHide: true,
      })
    );

    dispatch(updateLayout(data));
    //TODO

    if (updateLayouts) {
      console.log("*** HUHUUU");
      dispatch(updateLayous(data));
    }
  } catch (err) {
    console.error("VIRHE muokatessa asettelua:", err.message);

    dispatch(
      actionOpenAlert({
        open: true,
        severity: "warning",
        text: `VIRHE: ${err.response?.data?.msg}`,
        timeout: 5000,
        autoHide: true,
      })
    );
  }
};

export const actionEmptyOneLayout = () => async (dispatch) => {
  dispatch(getLayoutById(null));
};

export const actionDeleteLayout = ({ layoutId, orgID }) => async (dispatch) => {
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.delete(
      `${proxy}/api/layouts/${layoutId}/delete-layout?orgID=${orgID || ""}`,
      config
    );
    const data = response.data;

    console.log("RES", data);

    dispatch(deleteLayout(data));

    /*
    const socketData = {
      to: data.displays.map((el) => el._id),
    };

   socket?.emit("DB_SOCKET", socketData);

    dispatch(setLayoutCards(data.cards2));*/

    dispatch(
      actionOpenAlert({
        open: true,
        severity: "success",
        text: `Asettelu poistettu`,
        timeout: 2000,
        autoHide: true,
      })
    );
  } catch (err) {
    console.log("VIRHE poistettaessa asetteluja:", err.message);
    dispatch(handleResponses(err));
  }
};

export const actionUpdateLayoutRedux = (data) => async (dispatch) => {
  try {
    dispatch(updateLayout(data));
  } catch (err) {
    console.error("Virhe redux layout", err.message);
  }
};

export const actionSetLayoutInitials = () => async (dispatch) => {
  try {
    dispatch(setInitials());
  } catch (err) {
    console.error("Virhe initials layout", err.message);
  }
};
