import { useSelector } from "react-redux";

const OnlyForWinfo = ({ children }) => {
  const {
    data: { role },
  } = useSelector((state) => state.user);

  const isWinfo = role === "winfo";

  if (!isWinfo) {
    return null;
  }

  return children;
};

export default OnlyForWinfo;
