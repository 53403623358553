import checkCardSvg from "../../utils/checkCardSvg";

const CardIndexImg = ({ type, index }) => {
  const src = checkCardSvg(type, index);

  return (
    <div className="CardIndexImg">
      <img src={src} />
    </div>
  );
};

export default CardIndexImg;
