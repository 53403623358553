import { createSlice } from "@reduxjs/toolkit";

// Slice
const slice = createSlice({
  name: "socket",
  initialState: {
    socket: null,
  },
  reducers: {
    setSocket: (state, action) => {
      state.socket = action.payload.socket;
    },
  },
});
export default slice.reducer;
// Actions
const { setSocket } = slice.actions;

export const actionSetSocket = ({ socket }) => (dispatch) => {
  dispatch(setSocket({ socket }));
};
