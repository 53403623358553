import { Alert } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionCloseAlert } from "../../store/alert";
import "../styles/Alerts.scss";

export default function BasicAlert() {
  const dispatch = useDispatch();
  const alertData = useSelector((state) => state.alert);

  useEffect(() => {
    let timeout = undefined;
    if (alertData.autoHide && alertData.open) {
      const time = alertData.timeout || 5000;
      timeout = setTimeout(() => {
        dispatch(actionCloseAlert());
      }, time);
    }

    return () => {
      clearTimeout(timeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertData.open]);

  if (!alertData.open) {
    return null;
  }

  return (
    <div className="BasicAlertContainer">
      <Alert
        variant={alertData.variant || "filled"}
        severity={alertData.severity}
        className="alert"
      >
        {alertData.text}
      </Alert>
    </div>
  );
}
