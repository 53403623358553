import { Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import "./styles/Pages.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  actionDBLogin,
  actionRefreshLoginDB,
  actionSetLogoutReady,
} from "../store/DB";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import QRCode from "react-qr-code";
import socketIOClient from "socket.io-client";
import proxy from "../utils/proxy";

const socketID = uuidv4();

const DBLoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hostname = window.location.host;

  const { DBLoginData, DBLoggedIn, logoutReady } = useSelector(
    (state) => state.DB
  );

  const [connected, setConnected] = useState(false);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: (values) => {
      dispatch(actionDBLogin(values));
    },
  });

  useEffect(() => {
    dispatch(actionRefreshLoginDB());
    let socket;
    if (logoutReady) {
      dispatch(actionSetLogoutReady(false));
    }

    if (!DBLoggedIn && !connected) {
      console.log("LOGIN SOCKET");
      socket = socketIOClient(proxy, {
        query: { isLogin: true, customId: socketID },
      });
      socket?.on("connect", (data) => {
        setConnected(true);
      });

      socket?.on("DB_LOGIN", (data) => {
        console.log("* LISTEN", data);
        if (data.username && data.password) {
          const content = {
            username: data.username,
            password: data.password,

            response: {
              organizationId: data.organizationId,
              dbName: data.username,
            },
            socket,
          };

          dispatch(actionDBLogin(content));

          console.log("KIRJAUDU", data);
        }
      });
    }

    return () => {
      // tämä socket on vain kirjautumisen takia, joten katkaistaan yhteys
      if (socket) {
        socket?.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (DBLoggedIn && DBLoginData?.token) {
      navigate("/db?useAuth=true");
    }
  }, [DBLoggedIn]);

  return (
    <div className="Page DBLoginPage">
      <div className="MainContainer">
        <div className="div1">
          <div className="div1-left">
            <h1>Näytön kirjautuminen </h1>
            <p>{socketID}</p>
          </div>
        </div>

        <div className="div2">
          {connected ? (
            <>
              <p>Lue QR-koodi</p>
              <QRCode
                className="qrBox"
                size={256}
                style={{ maxWidth: "100%", width: "100%" }}
                value={`https://${hostname}/register/display?socketId=${socketID}`}
                viewBox={`0 0 256 256`}
              />
            </>
          ) : (
            <p className="qrBox">Socket ei yhdistetty</p>
          )}
          <div className="loginForm">
            <TextField
              id="outlined-basic"
              label="Käyttäjänimi"
              type="text"
              name="username"
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
            />

            <TextField
              id="outlined-basic"
              label="Salasana"
              name="password"
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
              type="password"
            />

            <Button onClick={formik.handleSubmit} variant="outlined" fullWidth>
              Kirjaudu
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DBLoginPage;
