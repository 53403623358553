import React from "react";
import { FormatDate } from "../../utils/dates";
import { AutoTextSize } from "auto-text-size";
import { useSelector } from "react-redux";

const ClockCard = React.forwardRef(({ styles }, ref) => {
  const { dateAndTime } = useSelector((state) => state.appData);


  return (
    <div className="ClockCard" ref={ref}>
      <div className="content">
        <AutoTextSize className="autoSize p-date" mode="box">
          {FormatDate(dateAndTime, "DD.MM.YYYY")}
        </AutoTextSize>

        <AutoTextSize className="autoSize p-time" mode="box">
          {FormatDate(dateAndTime, "HH:mm:ss")}
        </AutoTextSize>
      </div>
    </div>
  );
});

export default ClockCard;
