import { useSelector } from "react-redux";
import AppbarBasic from "./AppbarBasic";
import AppbarLoggedIn from "./AppbarLoggedIn";
import "../styles/Appbar.scss";

export default function Addbar() {
  const user = useSelector((state) => state.user);

  if (user.token) {
    return <AppbarLoggedIn />;
  }

  return <AppbarBasic />;
}
