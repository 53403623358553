import { Grid } from "@mui/material";
import "./styles/CardsLayout.scss";

export const ThreeCards = ({ children }) => {
  return (
    <Grid container spacing={2} className="ThreeCards">
      {children}
    </Grid>
  );
};
