import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveIcon from "@mui/icons-material/Remove";

const AddItemCard = ({ title, onClick, showNew }) => {
  return (
    <div className="CommonCard AddItemCard" onClick={() => onClick()}>
      <div className="header">
        <h4>{title}</h4>
      </div>

      <div className="content">
        {!showNew ? <AddCircleOutlineIcon /> : <RemoveIcon />}
      </div>
    </div>
  );
};

export default AddItemCard;
