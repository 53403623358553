import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";


export default function AppbarBasic() {
  const {showAppbar} = useSelector(state => state.appData)

  if (!showAppbar) {
    return null
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" className="Appbar">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Tervetuloa
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
