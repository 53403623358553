import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileUpload from "../../components/Files/FileUpload";
import { actionDeleteFile, actionGetFiles } from "../../store/fileBank";
import { FormatDate } from "../../utils/dates";

import "../styles/Pages.scss";

const UploadToStore = () => {
  const dispatch = useDispatch();
  const { files } = useSelector((state) => state.fileBank);
  const { selectedOrganization } = useSelector((state) => state.winfo);
  const [fileSrc, setFileSrc] = useState(null);
  //ei tunnista muuten muutoksia obj fileSrc
  const [click, setClick] = useState(0);

  useEffect(() => {
    if (!files || files?.length === 0) {
      const params = {
        orgID: selectedOrganization?._id,
      };
      dispatch(actionGetFiles(params));
    }
  }, []);

  const deleteFile = (fileBankId, publicId) => {
    dispatch(actionDeleteFile({ fileBankId, publicId }));
  };

  const onImgClick = (id) => {
    let tempFileSrc = fileSrc || {};
    let value = tempFileSrc[id];

    if (!value) {
      value = {};
      value = "url";
      tempFileSrc[id] = value;
    } else {
      delete tempFileSrc[id];
    }

    setFileSrc((state) => tempFileSrc);
    setClick((state) => state + 1);
  };

  return (
    <div className="Page UploadToStore">
      <div className="MainContainer">
        <div className="div1">
          <div className="div1-left">
            <h1>Lataa kuva</h1>
          </div>

          <div className="div1-right">
            <FileUpload />
          </div>
        </div>

        <div className="div2">
          <div className="images">
            {files.map((el, i) =>
              el.format === "jpg" ||
              el.format === "jpeg" ||
              el.format === "png" ? (
                <div className="CommonCard fileCard" key={i}>
                  <div className="header">
                    <Button onClick={() => deleteFile(el._id, el.publicId)}>
                      Poista
                    </Button>
                  </div>

                  <div className="content">
                    <img
                      alt={el.publicId}
                      src={
                        fileSrc && fileSrc[el._id]
                          ? el[fileSrc[el._id]]
                          : el.thumbnail
                      }
                      onClick={() => onImgClick(el._id)}
                    />
                  </div>

                  <div className="footer">
                    <p>{el.author?.username}</p>
                    <p>
                      {el.createdAt
                        ? FormatDate(el.createdAt, "dd DD.MM.YYYY")
                        : ""}
                    </p>
                  </div>
                </div>
              ) : el.format === "mp4" ? (
                <div className="CommonCard fileCard" key={i}>
                  <div className="header">
                    <Button onClick={() => deleteFile(el._id, el.publicId)}>
                      Poista
                    </Button>
                  </div>
                  <div className="content">
                    <video
                      alt={el.publicId}
                      src={el.url}
                      preload="auto"
                      playsInline
                      controls
                    />
                  </div>
                  <div className="footer">
                    <p>{el.author?.username}</p>
                    <p>
                      {el.createdAt
                        ? FormatDate(el.createdAt, "dd DD.MM.YYYY")
                        : ""}
                    </p>{" "}
                  </div>
                </div>
              ) : (
                <p>Tuntematon formaatti</p>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadToStore;
//                  <img alt={el.publicId} src={el.url} />
/* <video
                    alt={el.publicId}
                    src={el.url}
                    autoplay
                    preload="auto"
                    playsinline
                    controls
                  />*/
